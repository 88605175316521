<template>
  <div>
    <Expander persistent>
      <ExpanderItem>
        <template v-slot:header>
          <div>
            {{ dataItem.name }}
          </div>
        </template>
        <template v-slot:header-buttons>
<!--          <Btn-->
<!--            v-if="!tabletOrMobile && isNeedButton"-->
<!--            class="range-editor__start-test-btn"-->
<!--            :loading="btnLoading"-->
<!--            height="38px"-->
<!--            :color="btnColor"-->
<!--            :icon="'icons/start_game.svg'"-->
<!--            :saveIcon="'icons/refresh.svg'"-->
<!--            onmouseover="event.stopPropagation();"-->
<!--            @click.prevent.stop="handleBtnAction()"-->
<!--            @getIsActiveState="expanderIsActive"-->
<!--          >-->
<!--            {{ btnLabel }}-->
<!--          </Btn>-->
          <Btn
            v-if="isNeedButton"
            class="range-editor__start-test-btn"
            :disabled="btnSaveDisabled"
            :loading="btnSaveLoading"
            height="38px"
            :icon="'icons/start_game.svg'"
            :color="btnSaveColor"
            onmouseover="event.stopPropagation();"
            @click.prevent.stop="handleBtnAction()"
          >
            {{ btnSaveLabel }}
          </Btn>
        </template>

        <template v-slot:content>
          <div
            :class="[
              'item-options-editor',
              {
                'item-options-editor--readonly': isReadOnly
              }
            ]"
          >
            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.name }}
              </div>
              <div class="item-options-editor__value">
                <input
                  v-model="dataItem.name"
                  class="form-input item-options-editor__input item-options-editor__input--w-100"
                />
              </div>
            </div>
            <div class="item-options-editor__row">
              <div class="item-options-editor__label" />
              <div
                class="item-options-editor__value"
                :style="{ position: 'relative', 'pointer-events': 'all' }"
              >
                <button
                  :class="[
                    'item-options-editor__link-groups-btn',
                    {
                      'item-options-editor__link-groups-btn--active': linkingMode
                    },
                    'item-options-editor__link-groups-btn--turnOnPointerEvents'
                  ]"
                  @click="toggleLinkingMode()"
                >
                  {{ linkingModeBtnText }}
                </button>
                <FastFaqButton
                  @faqToggler="onFaqButtonClick"
                  :itemsInFaq="['LinkingMode', 'PlayingLinkingMode']"
                  lightName="linkingMode"
                  :style="{
                    top: '10px !important',
                    left: '310px'
                  }"
                />
              </div>
            </div>
            <div
              class="item-options-editor__row item-options-editor__row--link-groups"
            >
              <div class="item-options-editor__label">
                {{ localization.general.ItemGroupOptions.LinkedItems }}
              </div>
              <div class="item-options-editor__value">
                <img
                  v-if="linkedItemsLoading"
                  class="loading-gif"
                  src="/img/loading.gif"
                />
                <template v-else>
                  <i v-if="linkedItems.length === 0">
                    {{ localization.general.Common.Empty }}
                  </i>
                  <div
                    v-for="(linkedItem, index) in linkedItems"
                    v-else
                    :key="linkedItem.id"
                    class="item-options-editor__row--link-groups__item"
                  >
                    <img
                      v-show="linkingMode"
                      class="item-options-editor__row--link-groups__item__icon"
                      src="/icons/close-wrapped.svg"
                      @click="removeFromLinkedItems(index)"
                    />
                    {{ linkedItem.name }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </ExpanderItem>
    </Expander>
  </div>
</template>

<script>
import ItemOptionsEditorMixin from '@/components/ItemOptionsEditor/ItemOptionsEditorMixin';
import Btn from '@/components/UI/Btn';
import FastFaqButton from '@/components/FastFaqDialog/FastFaqButton';

export default {
  name: 'ItemGroupOptionsEditor',
  props: {
    linkedItemsProvider: Function,
    item: Object,
    btnDisabled: Boolean,
    btnLoading: Boolean,
    btnColor: String,
    btnLabel: String,
    btnSaveDisabled: Boolean,
    btnSaveLoading: Boolean,
    btnSaveColor: String,
    btnSaveLabel: String,
    faqToggler: Function
  },
  data() {
    return {
      dataItem: {
        name: '',
        linksGroup: null
      },

      linkedItems: [],
      linkingMode: null,
      linkedItemsLoading: true
    };
  },
  components: { Btn, FastFaqButton },
  mixins: [ItemOptionsEditorMixin],
  computed: {
    linkingModeBtnText() {
      return this.localization.general.ItemGroupOptions[
        this.linkingMode ? 'DeactivateLinkingMode' : 'ActivateLinkingMode'
      ];
    },
    expanderIsActive(state) {
      return state;
    }
  },
  methods: {
    onFaqButtonClick(nodes) {
      this.$emit('faqToggler', nodes);
    },
    removeFromLinkedItems(index) {
      this.linkedItems.splice(index, 1);
      this.updateIsAvailable();
    },
    handleBtnAction() {
      this.$emit('btnAction');
    },
    linkItems(items) {
      this.linkedItems = items;
      this.updateIsAvailable();
    },
    turnOffLinkingMode() {
      this.linkingMode = null;
      this.$emit('linkingModeToggle', null);
    },
    toggleLinkingMode() {
      this.$track.activateLinkingMode();

      this.linkingMode = this.linkingMode ? null : this.item.type;
      this.$emit('linkingModeToggle', this.linkingMode);
    },
    submit() {
      let linkedItemsIDs =
        this.linkedItems.length > 0
          ? this.linkedItems.map(group => {
              return {
                id: group.id
              };
            })
          : null;

      if (!this.dataItem.name) {
        return this.$toast.error(this.localization.general.Simple.EnterName);
      }

      return {
        validated: true,
        data: {
          name: this.dataItem.name,
          linkedItems: linkedItemsIDs
        }
      };
    },
    async getLinkedItems() {
      if (!this.expanderIsActive || !this.item) return;

      this.linkedItemsLoading = true;
      console.log(this.item, 'THIS ITEM IN LINKED DIAPASON');
      if (this.item.linksGroup?.items.length === 0) {
        this.linkedItems = [];
        this.linkedItemsLoading = false;
        return;
      }
      // get linked items from prop
      let linkedItemsOfCurrentItem = this.item.linksGroup;
      console.log(this.item, 'ITEM GROUP OPTIONS EDITOR: this.item');
      console.log(
        linkedItemsOfCurrentItem,
        'ITEM GROUP OPTIONS EDITOR: linkedItemsOfCurrentItem1'
      );
      // load linked items info from IDs, gotten from prop
      if (linkedItemsOfCurrentItem) {
        let linkedItemsIDs = linkedItemsOfCurrentItem.items
          .map(linkedItem => linkedItem.id)
          .join(',');
        console.log(
          linkedItemsIDs,
          'ITEM GROUP OPTIONS EDITOR: linkedItemsOfCurrentItem'
        );
        this.linkedItems = await this.linkedItemsProvider(linkedItemsIDs);
      } else {
        this.linkedItems = [];
      }
      this.$emit('linkedItemsChange', this.linkedItems);
      this.linkedItemsLoading = false;
    }
  },
  // inject: ['tabletOrMobile'],

  watch: {
    item: {
      handler() {
        console.log('wathcerrc iterm');
        this.getLinkedItems();
      },
      immediate: true
    },
    expanderIsActive: {
      handler() {
        console.log('watherrc expander');
        this.getLinkedItems();
      }
    }
  }
};
</script>
<style lang="scss" scoped src="./ItemGroupOptionsEditor.scss" />
