<template>
  <div class="container">
    <div class="row">
      <div
        :class="[
          'ranges-list',
          {
            'hidden-sm': rangesFooterCurrentView !== rangesFooterViewList,
            'visible-sm': rangesFooterCurrentView === rangesFooterViewList
          }
        ]"
      >
        <transition name="fade">
          <div v-if="$store.getters['user/userLevel'] < 1" class="hide-all" />
        </transition>
        <div class="ranges-list__actions">
          <DropdownMenu
            :header-label="localization.general.MainPage.CreateItemButton"
            :items="menuItemsForCreating"
            background-color="blue"
            class="ranges-list__actions__item u-mb-2"
            height="38"
          />
          <DropdownMenu
            :items="menuItemsForTools"
            background-color="dark"
            class="ranges-list__actions__item u-mb-2"
            header-label="Tools"
            height="38"
          />
          <button
            :disabled="selectedItems.length === 0 && !itemIsSelected"
            class="ranges-list__actions__item ranges-list__actions__item--delete u-mb-2"
            @click="openDeleteDialog()"
          >
            {{ localization.general.MainPage.DeleteItemButton }}
          </button>
        </div>

        <List
          ref="rangesList"
          :childrenProvider="getItems"
          :sortFunction="sortItems"
          class="ranges-list__items"
          @currentItemChange="onCurrentItemChange"
          @groupOpen="onGroupOpen"
          @itemParentChange="onItemParentChange"
          @selectedItemsChange="onSelectedItemsChange"
          :premiumEditor="true"
        >
          <template v-slot:card-body-content="item">
            <div v-if="!item.availabilityLayer" class="card--disabled" />
          </template>
          <template v-slot:card-inner="item">
            <div class="list__item__card__label">
              <span
                v-if="checkPremiumItemError(item)"
                class="error-premium-diapason"
              >
                !
              </span>
              {{ item.name }}
            </div>

            <div class="list__item__card__diapason__info">
              <div
                v-if="item.hasOwnProperty('price') && item.price !== null"
                class="list__item__card__diapason__info__price"
              >
                {{ item.price }}$
              </div>
            </div>
            <div
              v-if="item.type === 'Group' && linkingMode"
              style="margin-left: 10px"
              @click="
                $event.stopPropagation();
                linkItem(item);
              "
            >
              <div v-if="itemIsLinked(item)">
                <img
                  class="list__item__card__diapason__link-to-group"
                  src="/icons/close-wrapped.svg"
                />
              </div>
              <div v-else>
                <img
                  class="list__item__card__diapason__link-to-group"
                  src="/icons/plus.svg"
                />
              </div>
            </div>
          </template>
        </List>
      </div>
      <div
        :class="[
          'range-editor',
          {
            'hidden-sm': rangesFooterCurrentView !== rangesFooterViewEditor,
            'visible-sm': rangesFooterCurrentView === rangesFooterViewEditor
          }
        ]"
      >
        <TabsNav v-model="rangeTab">
          <TabsNavBtn>
            Editor
          </TabsNavBtn>
          <TabsNavBtn>
            Viewer
          </TabsNavBtn>
        </TabsNav>
        <Tabs v-model="rangeTab">
          <Tab class="p-x-0">
            <PremiumRangeEditor
              ref="rangeEditor"
              :is-blocked="isBlocked"
              :item="currentItem"
              :rangesOfGroup="rangesOfGroup"
              @linkedItemsChange="onLinkedItemsChange"
              @linkingModeToggle="onLinkingModeToggle"
            />
          </Tab>
          <Tab class="p-x-0">
            <StoreRangeViewer v-if="itemIsSelected" :item="currentItem" />
          </Tab>
        </Tabs>
      </div>
    </div>

    <UserRangesSelectorDialog
      v-model="userRangesSelectorDialog"
      :isAvailable="true"
      @rangesSelect="onRangesSelect"
    />
    <ItemsToPremiumDialog
      v-model="itemsToPremiumDialog"
      :items="userRangesSelectorValues.selectedItems"
      :userId="userRangesSelectorValues.userId"
      @setPremiumItems="onSetUserItemsToPremium"
    />

    <UserRangesSelectorDialog
      v-model="rangesSelectorForPremiumItemsToUserDialog"
      :isAvailable="true"
      :item-select-required="false"
      :multiple-select="false"
      types="Group"
      @rangesSelect="onRangesSelectorForPremiumItemsToUserChange"
    >
      <template v-slot:title>
        Выберите одну группу, либо пропустите
      </template>
    </UserRangesSelectorDialog>
    <PremiumItemsToUserDialog
      v-model="premiumItemsToUserDialog"
      :items="computedSelectedItems"
      :to-group="rangesSelectorForPremiumItemsToUserValues.toGroup"
      :user-id="rangesSelectorForPremiumItemsToUserValues.userId"
      @setPremiumItemsToUser="onSetPremiumItemsToUser"
    />

    <CreateGroupDialog
      v-model="showCreateGroupDialog"
      :handler="onGroupCreate"
    />
    <CreateRangeDialog
      v-model="showCreateRangeDialog"
      :handler="onRangeCreate"
    />

    <DeleteItemsDialog
      v-model="showDeleteDialog"
      :items="computedSelectedItems"
      :handler="handleConfirmDelete"
      @itemsDelete="onItemsDelete()"
    />

    <RangeViewChanger @changeView="changeRangesFooterView" />
  </div>
</template>

<script>
import {
  COPY_PREMIUM_ITEMS,
  CREATE_PREMIUM_ITEM_DIAPASON,
  CREATE_PREMIUM_ITEM_GROUP,
  DELETE_PREMIUM_ITEMS,
  GET_ALL_PREMIUM_ITEMS,
  GET_PREMIUM_ITEMS_CHILDREN,
  MOVE_PREMIUM_ITEM,
  SET_PREMIUM_ITEMS_TO_USER
} from '@/api/premiumItems';

import ItemsToPremiumDialog from '@/components/ItemsToPremiumDialog';

import PremiumRangeEditor from '@/components/RangeEditor/PremiumRangeEditor';
import UserRangesSelectorDialog from '@/components/UserRangesSelectorDialog';
import StoreRangeViewer from '@/components/StoreRangeViewer';

import { Tab, Tabs, TabsNav, TabsNavBtn } from '@/components/UI/Tabs';
import PremiumItemsToUserDialog from '@/components/PremiumItemsToUserDialog';

import RangeViewsMixin from '../../../mixins/RangeViewsMixin';
import DropdownMenu from '@/components/UI/DropdownMenu';
// import DefaultRangeEditor from "@/components/RangeEditor/DefaultRangeEditor";

export default {
  name: 'PremiumRangeEditorTab',
  mixins: [RangeViewsMixin],
  components: {
    DropdownMenu,
    PremiumItemsToUserDialog,
    TabsNavBtn,
    TabsNav,
    Tabs,
    Tab,
    StoreRangeViewer,
    UserRangesSelectorDialog,
    ItemsToPremiumDialog,
    PremiumRangeEditor
  },
  data() {
    return {
      rangeTab: 0,
      itemsToPremiumDialog: false,

      premiumItemsToUserDialog: false,
      rangesSelectorForPremiumItemsToUserDialog: false,
      rangesSelectorForPremiumItemsToUserValues: {},

      userRangesSelectorDialog: false,
      userRangesSelectorValues: {},

      defaultDescriptors: [
        {
          type: 'Fastspring',
          Id: 'managed-premium-item'
        }
      ]
    };
  },
  computed: {
    computedSelectedItems() {
      if (this.selectedItems.length === 0 && !this.currentItem) return [];

      return this.selectedItems.length > 0
        ? this.selectedItems
        : [this.currentItem];
    },
    itemIsSelected() {
      return this.currentItem !== null;
    },
    menuItemsForTools() {
      return [
        {
          label: 'To Premium',
          handler: this.openItemsToPremiumDialog
        },
        {
          label: 'To User',
          disabled: this.computedSelectedItems.length === 0,
          handler: this.openPremiumItemsToUserDialog
        }
      ];
    }
  },
  methods: {
    changeRangesFooterView(view) {
      this.rangesFooterCurrentView = view;
    },
    onRangesSelect(value) {
      this.userRangesSelectorValues = value;
      this.itemsToPremiumDialog = true;
    },
    checkPremiumItemError(item) {
      const priceIsNull = item.price <= 0;
      const layerExists = item.availabilityLayer;
      const descriptorIsNull = item.storeProductDescriptors === null;

      let descriptorItemsAreNull = false;
      if (!descriptorIsNull) {
        descriptorItemsAreNull = item.storeProductDescriptors.every(
          descriptor => {
            return !/\S/.test(descriptor.Id);
          }
        );
      }

      return (
        layerExists &&
        (descriptorIsNull || descriptorItemsAreNull || priceIsNull)
      );
    },
    async onSetPremiumItemsToUser(data) {
      await SET_PREMIUM_ITEMS_TO_USER(data);
      this.$toast.success('PREMIUM ITEMS SUCCESSFULLY SETTED TO USER');
    },
    onRangesSelectorForPremiumItemsToUserChange(data) {
      this.rangesSelectorForPremiumItemsToUserValues = data;
      this.rangesSelectorForPremiumItemsToUserValues.toGroup =
        data.selectedItems[0];
      delete this.rangesSelectorForPremiumItemsToUserValues.selectedItems;

      this.premiumItemsToUserDialog = true;
    },
    onItemParentChange(data) {
      this.itemParentChangeHandler(data, MOVE_PREMIUM_ITEM);
    },

    onSetUserItemsToPremium() {
      this.$refs.rangesList.refresh();
    },
    openItemsToPremiumDialog() {
      this.userRangesSelectorDialog = true;
    },
    openPremiumItemsToUserDialog() {
      this.rangesSelectorForPremiumItemsToUserDialog = true;
    },
    async copyItem() {
      await this.handleCopyItem(COPY_PREMIUM_ITEMS);
    },

    async onGroupCreate(newName) {
      console.log(newName, 'newName');
      const items = this.$refs.rangesList.getItemsOfCurrent();
      let order = this.getLastOrder(items);

      let newGroup = {
        name: newName.name,
        order: order,
        type: 'Group',
        price: 0,
        priceModifier: null,
        availabilityLayer: null,
        localization: {},
        storeProductDescriptors: this.defaultDescriptors,
        parentId: this.currentItemParentId,
        diapason: null
      };
      newGroup.id = await CREATE_PREMIUM_ITEM_GROUP(newGroup);
      console.log('Created new group', newGroup);

      this.$refs.rangesList.addData(newGroup);
    },
    async onRangeCreate(newRange) {
      const items = this.$refs.rangesList.getItemsOfCurrent();
      const ranges = items.filter(item => item.type === 'Diapason');
      let order = this.getLastOrder(ranges);

      newRange.order = order;
      newRange.storeProductDescriptors = this.defaultDescriptors;
      newRange.parentId = this.currentItemParentId;

      const newItemId = await CREATE_PREMIUM_ITEM_DIAPASON(newRange);

      this.$refs.rangesList.addData({
        type: 'Diapason',
        id: newItemId,
        order: order,
        parentId: this.currentItemParentId,
        name: newRange.name,
        price: 0,
        priceModifier: null,
        availabilityLayer: null,
        localization: {},
        storeProductDescriptors: this.defaultDescriptors,
        diapason: {
          additionalProperties: newRange.additionalProperties,
          participants: newRange.participants,
          tableFormat: newRange.tableFormat,
          hands: {}
        }
      });
      console.log('Created new range', newRange);
    },
    groupOpened(ranges) {
      this.rangesOfGroup = ranges;
    },

    async getItems(item) {
      console.log('get item', item);
      if (item)
        return await GET_PREMIUM_ITEMS_CHILDREN(item.id, {
          isAvailable: true
        });

      const data = await GET_ALL_PREMIUM_ITEMS();

      return [...data.groups, ...data.diapasonsWithoutGroup];
    },
    async handleConfirmDelete() {
      console.log('delet');
      await this.handleDeletion(DELETE_PREMIUM_ITEMS);
    },
    startTest() {
      this.$store.dispatch('user/SET_CURRENT_MAIN_PAGE', 1);
      this.$store.dispatch('ranges/startTest');
    }
  }
};
</script>

<style lang="scss">
.card--disabled {
  position: absolute;
  left: 0;
  z-index: 5;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  user-select: none;
  background: rgba(255, 255, 255, 0.5);
}

.error-premium-diapason {
  color: red;
}
</style>
