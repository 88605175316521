<template>
  <div class="range-table-editor">
    <table class="range-table-editor__matrix table-fixed-layout">
      <tbody>

        <tr
          v-for="(row, x) in rowIterator"
          :key="x"
          :class="[
            'range-table-editor__row',
            isGameView ? 'range-table-editor__row--game' : ''
          ]"
        >
          <td
            v-for="(hand, y) in row"
            :key="y"
            :class="[
              'range-table-editor__cell',
              `range-table-editor__cell__${hand.name}`,
              {
                'range-table-editor__cell--selected-for-test': hand.useForTest  && hidden(x, y),
                'range-table-editor__cell--has-weight':
                  isWeightValid(hand.weight) &&
                  !isHeatmapActive &&
                  !speedHeatMapActive && hidden(x, y),
                'range-table-editor__cell--highlighted':
                  highlightHands && highlightHands.includes(hand.name) && hidden(x, y)
              }
            ]"
            @mousedown="hidden(x, y) && startSelection(hand)"
            @mouseover="hidden(x, y) && selectHand(hand)"
          >
            <div
              class="range-table-editor__level"
              v-if="isActiveLevelMode && isRange && hand.level && isHeroActive && hidden(x, y)"
            >
              {{ hand.level }}
            </div>
            <div class="range-table-editor__background" v-if="hidden(x, y)" />

            <div
              class="range-table-editor__heatmap"
              v-if="
                isHeatmapActive && (hand.time || hand.correct || hand.incorrect) && hidden(x, y)
              "
            >
              <div
                :style="{
                  backgroundColor: getColorByFilter(
                    hand.correct,
                    hand.incorrect,
                    hand.name
                  ),
                  width: '100%',
                  height: '100%'
                }"
              />
            </div>

            <div class="range-table-editor__weight" v-if="hidden(x, y)">
              <span
                v-for="weightName in allPossibleWeights"
                :key="weightName"
                :style="{
                  width:
                    (hand !== 'undefined' &&
                    hand != null &&
                    hand.weight !== 'undefined' &&
                    hand.weight != null &&
                    hand.weight[weightName] > 0
                      ? hand.weight[weightName]
                      : 0) *
                      100 +
                    '%',
                  'background-color': actionColors[weightName]
                }"
                :weight-type="weightName"
                :class="[
                  'range-table-editor__weight-value',
                  {
                    'range-table-editor__weight-value--visible':
                      hand !== 'undefined' &&
                      hand != null &&
                      hand.weight !== 'undefined' &&
                      hand.weight != null &&
                      hand.weight[weightName] > 0
                        ? hand.weight[weightName]
                        : 0
                  }
                ]"
              />
            </div>

            <div class="absolute-position range-table-editor__foreground" v-if="hidden(x, y)" />

            <div class="absolute-position range-table-editor__info" :class="[
              {'hidden-position-cart': !hidden(x, y)}
            ]">
              <div class="range-table-editor__hand-name">
                {{ hand.name }}
              </div>
              <p
                v-if="
                  hand.statistics && hidden(x, y) &&
                    showStatistics &&
                    (hand.statistics.correct > 0 ||
                      hand.statistics.incorrect > 0) &&
                    activeStatTab !== 'Speed'
                "
                :class="[
                  'range-table-editor__statistics',
                  {
                    'range-table-editor__statistics--with-mistakes':
                      hand.statistics.incorrect > 0
                  }
                ]"
              >
                {{ formatStatisticsValue(hand.statistics.incorrect) }}
                /
                {{
                  formatStatisticsValue(
                    hand.statistics.correct + hand.statistics.incorrect
                  )
                }}
              </p>
              <p
                v-else-if="
                  activeStatTab === 'Speed' && hidden(x, y) &&
                    hand.statistics &&
                    showStatistics &&
                    (hand.statistics.correct > 0 ||
                      hand.statistics.incorrect > 0)
                "
                :class="['range-table-editor__statistics']"
              >
                {{ (hand.time / (hand.correct + hand.incorrect)).toFixed(1) }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="range-table-editor__state" v-if="!isMockTable && isRange">
      <div
        class="range-table-editor__state__item"
        v-for="(item, index) in weights"
        :key="index"
      >
        <b>{{ item.charAt(0).toUpperCase() + item.slice(1) }}: </b>
        <div class="range-table-editor__state__item__value">
          {{ (parseFloat(rangeState[item]) * 100).toFixed(2) }}% ({{
            parseInt(rangeState[`${item}Count`])
          }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ORDERED_HANDS_RANGE,
  SEQUENTIAL_HANDS_RANGE
} from '@/constants/handsRange.js';
import {
  GET_PERCENT_FROM_TABLE_DATA,
  GET_COUNT_FROM_TABLE_DATA
} from '@/utils/percent.js';
import { GET_CURRENT_WEIGHT } from '@/utils/weight.js';
import {mapGetters} from "vuex";

const SELECTION_MODE_WEIGHT = 'weight';
const SELECTION_MODE_TEST = 'test';

export default {
  name: 'RangeTableEditor',
  props: {
    qualityHeatmapActive: { type: Boolean, default: false },
    speedHeatMapActive: { type: Boolean, default: false },
    isSpeedQualityActive: { type: Boolean, default: false },
    heatMapHands: { type: Array, default: null },
    activeStatTab: { type: String, default: '' },
    isHeatmapActive: { type: Boolean, default: false },
    speedData: { type: Object, default: null },
    statisticsOfGroup: Array,

    isRange: Boolean,
    changeMaxLevel: Function,
    playLevel: Number,
    isActiveLevelMode: Boolean,
    clearStatistic: Function,
    isMockTable: {
      type: Boolean,
      default: false
    },

    heroPosition: String,
    hands: Object,
    statistics: Object,
    highlightHands: Array,
    inverseOrder: Object,
    isInverse: Boolean,
    isGameView: Boolean,
    isAnimated: {
      type: Boolean,
      default: false
    },

    activeParticipant: {
      type: [Object, String]
    },
    actionColors: {
      type: Object,
      default() {
        return {
          fold: 'rgb(115, 216, 255)',
          call: 'rgb(164, 221, 0)',
          raise: 'rgb(159, 4, 27)',
          allin: 'rgb(250, 40, 255)'
        };
      }
    },
    //available types: weight, test
    selectionMode: String,
    //weight: { fold: 0.3, call: 0.3, raise: 0.0, allin: 0.4 }
    weight: {
      type: Object,
      default() {
        return {
          fold: 0.25,
          call: 0.25,
          raise: 0.25,
          allin: 0.25,
          check: 0.25
        };
      }
    },
    isReadOnly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showStatistics: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      elements: [],
      timeouts: [],
      intervals: [],
      isVisible: true,
      hasScroll: false,
      endOfScroll: false,
      lastHandSelected: '',
      allPossibleWeights: ['fold', 'check', 'call', 'raise', 'allin'],
      weights: ['fold', 'check', 'call', 'raise', 'allin'],
      //each hand:
      //hand.name = name;
      //hand.useForTest = false;
      //hand.weight = { fold: 0.3, call: 0.3, raise: 0.0, allin: 0.4 }
      //hand.statistics = { correct: 87, incorrect: 32 }
      handsRange: [],
      //null - selection not started, true - select, false - deselect
      currentFillState: null,
      //selection states: true - started, false - not started
      selectionState: false,
      rangeState: {
        fold: 0,
        check: 0,
        call: 0,
        raise: 0,
        allin: 0
      }
    };
  },
  methods: {
    getColorByFilter(correct, incorrect, handName) {
      console.log(correct, incorrect, 'bfilter');
      const total = correct + incorrect;

      if (this.activeStatTab === 'Quality') {
        const quality = correct / total;
        console.log(quality, 'qual');
        const finishRgb = this.getColorHsl(quality);
        return finishRgb;
      }

      if (this.activeStatTab === 'Speed') {
        const quality = this.speedData[handName];
        const finishRgb = this.getColorHsl(quality);
        console.log(finishRgb, quality, 'finishSpeed');
        return finishRgb;
      }
    },
    hidden(x, y) {
      if(this.globalShortDesk){
        if((y > 8) || (x > 8)){
          return false
        }
      }
      return true;
    },
    getAverageSpeed(correctAnswers, incorrectAnswers, totalTime) {
      var totalGames = correctAnswers + incorrectAnswers;

      var fastestTime = totalTime / totalGames; // Предполагаем, что самый быстрый ответ был затрачен на любой из вариантов (правильный или неправильный)

      var averageSpeed = totalTime / totalGames / fastestTime;

      // Ограничиваем результат в диапазоне от 0 до 1
      averageSpeed = Math.max(0, Math.min(averageSpeed, 1));

      return averageSpeed;
    },
    getColorHsl(value) {
      const h = value * 120; // Adjusted to map 0 to red and 1 to green
      return 'hsl(' + h + ', 85%, 60%)';
    },
    getColor(value) {
      const red = Math.round(value * 255); // Higher quality values result in more red
      const green = Math.round((1 - value) * 255); // Higher quality values result in less green

      let r = Math.round(255 - (255 - red) * value);
      let g = Math.round(255 - (255 - green) * value);
      return `rgb(${r}, ${g}, 0)`;
    },
    getMaxLevel() {
      if (this.isReadOnly) return;

      let maxLevel = 0;
      for (let hand of Object.values(this.hands)) {
        if (hand.level > maxLevel) {
          maxLevel = hand.level;
        }
      }
      console.log(maxLevel, 'MAXLEVEL');

      this.$emit('changeMaxLevel', maxLevel);
      // return maxLevel;
    },
    startAnimation() {
      const elements = document.getElementsByClassName(
        'range-table-editor__weight'
      );
      this.elements = elements;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        // Generate a random timeout value between 0 and 5 seconds
        const timeout = Math.random() * 5000;
        this.timeouts.push(timeout);
        setTimeout(() => {
          const interval = setInterval(() => {
            console.log('INTERVAL');
            element.classList.toggle('blinking');
          }, 5000);
          this.intervals.push(interval);
        }, timeout);
      }
    },
    ///////////////////////////////////FUNCTIONS FOR PUBLIC USAGE
    //Fills specified hands with selected weight
    //[ { "data": [ "TT", "JJ", "T8s" ], "weight": 0.5 }, { "data": [ "TT", "JJ", "T8s" ], "weight": 1 },... ]
    fillWeights(hands) {
      console.log(hands, '???', this.weight);
      if (
        this.weight.call === 1 ||
        this.weight.raise === 1 ||
        this.weight.allin === 1 ||
        this.weight.check === 1
      ) {
        hands.forEach(current => {
          if (Array.isArray(current?.data)) {
            current.data.forEach(hand => {
              if (hand && current.weight) {
                let pos = this.getHandPosition(hand);
                let target = this.getHandByPosition(pos.x, pos.y);
                console.log('pos', pos, 'target:', target);
                console.log(target, 'target');
                if (this.selectionMode === SELECTION_MODE_WEIGHT) {
                  let weight = target.weight ?? this.emptyWeight();

                  console.log(hand, weight, 'weightonparse');
                  let type = '';
                  if (this.weight.call === 1) type = 'call';
                  else if (this.weight.raise === 1) type = 'raise';
                  else if (this.weight.allin === 1) type = 'allin';
                  else if (this.weight.check === 1) type = 'check';

                  const buildWeight = this.buildWeight(
                    weight.fold * 100,
                    weight.check * 100,
                    weight.call * 100,
                    weight.raise * 100,
                    weight.allin * 100
                  );
                  console.log(
                    current.weight,
                    type,
                    buildWeight,
                    'converted1 fillWeights'
                  );
                  let converted = GET_CURRENT_WEIGHT(
                    type,
                    current.weight,
                    buildWeight
                  );

                  console.log(converted, 'converted2 fillWeights');
                  weight.check = (converted.check ?? 0) / 100;
                  weight.call = converted.call / 100;
                  weight.raise = converted.raise / 100;
                  weight.allin = converted.allin / 100;
                  weight.fold = converted.fold / 100;

                  this.$set(target, 'weight', weight);
                }

                target.useForTest = true;
              }
            });
            const changes = this.applyChanges();
            this.$emit('handsSelect', changes);
          }
        });
      }
      this.recalculateState();
    },
    //Specifies range of rands which have to be filled
    setHandsRangeSize(value) {
      console.log('THIS RANGE HANDS123', this.handsRange);
      console.log('setHandsRangeSize: ', value);

      if (value >= 0 && value <= 169) {
        let hands = ORDERED_HANDS_RANGE().slice(0, value);
        this.handsRange.forEach(hand => {
          console.log(hand, 'handsss');

          if (hands.some(h => hand.name == h)) {
            if (this.selectionMode == SELECTION_MODE_WEIGHT) {
              console.log(hand, 'hand123: before');
              // if (this.isActiveLevelMode) {
              //   console.log(hand, 'HGD');
              //   hand.level = this.playLevel;
              // } else {
              //   hand.level = 0;
              // }

              console.log(hand, 'hand123: afters');

              if (!hand.weight) {
                hand.level = this.playLevel;
                this.$set(
                  hand,
                  'weight',
                  this.buildWeight(
                    this.weight.fold,
                    this.weight.check,
                    this.weight.call,
                    this.weight.raise,
                    this.weight.allin
                  )
                );
              }

              hand.useForTest = true;
            } else if (this.selectionMode == SELECTION_MODE_TEST) {
              hand.useForTest = true;
            }
          } else {
            if (this.selectionMode == SELECTION_MODE_WEIGHT) {
              this.$set(hand, 'weight', null);
              console.log(hand, 'hnd');

              hand.useForTest = false;
            } else if (this.selectionMode == SELECTION_MODE_TEST) {
              hand.useForTest = false;
            }
          }
        });
      } else if (value == 0) {
        console.log(value, 'VALUE 0');
        if (this.selectionMode == SELECTION_MODE_WEIGHT) {
          this.clearWeights();
        } else if (this.selectionMode == SELECTION_MODE_TEST) {
          this.clearTestHands();
        }
      } else throw 'Value must be in range [0; 169]';
      this.recalculateState();
      let { data } = this.applyChanges();

      console.log(data, 'DATA HANDS');
      this.$emit('scrollChanges', data.hands);
    },
    //Clears statistics for all hands
    clearStatistics() {
      this.$emit('clearStatistic');
      // this.handsRange.forEach(hand => {
      //   this.$set(hand, 'statistics', this.emptyStatistics());
      // });
    },
    //Clears weights for all hands
    clearWeights() {
      this.handsRange.forEach(hand => {
        console.log(hand, 'HAND IN CLEAR WEIGHT');
        this.$set(hand, 'weight', this.emptyWeight());
      });
      const changes = this.applyChanges();
      console.log('CHANGES IN RTE', changes);
      this.$emit('handsSelect', changes);

      this.recalculateState();
    },
    //Set useForTest to false for all hands
    clearTestHands() {
      this.handsRange.forEach(hand => {
        hand.useForTest = false;
      });
      const changes = this.applyChanges();
      console.log('CHANGES IN RTE', changes);
      this.$emit('handsSelect', changes);
    },
    //Clears statistics and weights for all hands
    clearHandsRange() {
      this.handsRange.forEach(hand => {
        this.$set(hand, 'weight', this.emptyWeight());
        this.$set(hand, 'statistics', this.emptyStatistics());
      });
      this.recalculateState();
    },
    applyChanges() {
      let range = {};
      range.hands = {};
      console.log('apppppplied');
      let levelsInRange = [];

      this.handsRange.forEach(localHand => {
        let key = localHand.name;
        let rangeHand = null;

        console.log(localHand, 'localHand');

        if (range.hands.hasOwnProperty(key)) {
          rangeHand = range.hands[key];
        } else {
          rangeHand = {};
          range.hands[key] = rangeHand;
        }

        //TODO: Здесь идет обработка левла руки и передача на уровень выше
        console.log(
          this.isWeightValid(localHand.weight),
          localHand,
          'weightValid'
        );

        if (!levelsInRange.includes(localHand.level) && localHand.level > 0) {
          levelsInRange.push(localHand.level);
        }

        console.log(localHand, localHand.name, 'localHand');

        rangeHand.level = localHand.level;

        if (this.isWeightValid(localHand.weight)) {
          rangeHand.allin = localHand.weight.allin;
          rangeHand.call = localHand.weight.call;
          rangeHand.fold = localHand.weight.fold;
          rangeHand.raise = localHand.weight.raise;
          rangeHand.check = localHand.weight.check;

          rangeHand.useForTest = localHand.useForTest ?? false;
        } else if (localHand.useForTest) {
          rangeHand.allin = 0;
          rangeHand.call = 0;
          rangeHand.fold = 0;
          rangeHand.raise = 0;
          rangeHand.check = 0;

          rangeHand.useForTest = true;
        } else {
          if (range.hands.hasOwnProperty(key)) {
            delete range.hands[key];
          }
        }
      });

      // Here is the added code that replaces any missing level numbers in the range
      levelsInRange.sort(function(a, b) {
        return a - b;
      });

      for (let i = 0; i < levelsInRange.length; i++) {
        if (levelsInRange[i] != i + 1) {
          let replaceLevel = i + 1;
          console.log(
            'Replacing level ' +
              levelsInRange[i] +
              ' with level ' +
              replaceLevel
          );
          // levelsInRange[i] = replaceLevel;
          console.log(range, 'actual range to replace');

          for (const key in range.hands) {
            if (range.hands.hasOwnProperty(key)) {
              const rangeHand = range.hands[key];
              if (rangeHand.level === levelsInRange[i]) {
                console.log(range.hands[key], replaceLevel, 'isreplace');
                range.hands[key].level = replaceLevel;
              }
            }
          }
        }
      }

      this.getMaxLevel();

      console.log(levelsInRange, 'LEVELS IN RANGE');
      range.statistics = {
        hands: {}
      };

      this.handsRange.forEach(localHand => {
        let key = localHand.name;
        let rangeHand = null;

        if (range.statistics.hands.hasOwnProperty(key)) {
          rangeHand = range.statistics.hands[key];
        } else {
          rangeHand = {};
          range.statistics.hands[key] = rangeHand;
        }

        if (localHand.statistics) {
          rangeHand.correct = localHand.statistics.correct;
          rangeHand.incorrect = localHand.statistics.incorrect;
        } else {
          if (range.statistics.hands.hasOwnProperty(key)) {
            delete range.statistics.hands[key];
          }
        }
      });
      console.log(range, 'RANGE IN APPLY');
      // this.updateTable();

      return {
        validated: true,
        data: range
      };
    },
    ///////////////////////////////////FUNCTIONS FOR PRIVATE USAGE
    updateTable() {
      console.log('UPDATE TABLE');
      this.loadTableCells();
      console.log(this.hands, 'this hands');
      if (this.hands) {
        Object.keys(this.hands).forEach(key => {
          console.log(this.hands[key], 'key');
          let element = this.hands[key];
          let weight = this.buildWeight(
            element.fold,
            element.check,
            element.call,
            element.raise,
            element.allin
          );
          let index = this.getHandIndex(key);
          let hand = this.handsRange[index];

          // hand.level = 12;
          console.log(this.hands[key].level, 'level obj');
          hand.level = this.hands[key].level >= 1 ? this.hands[key].level : 1;
          hand.correct = this.hands[key].correct;
          hand.incorrect = this.hands[key].incorrect;
          hand.time = this.hands[key].time;

          hand.useForTest = element.useForTest;
          console.log(hand, weight, element, 'hand and weight');

          this.$set(hand, 'weight', weight);
        });
      }

      if (this.statistics) {
        Object.keys(this.statistics.hands).forEach(key => {
          let element = this.statistics.hands[key];
          let statistics = {
            correct: element.correct,
            incorrect: element.incorrect
          };
          let index = this.getHandIndex(key);
          let hand = this.handsRange[index];

          this.$set(hand, 'statistics', statistics);
        });
      }

      this.recalculateState();
    },
    completeSelection() {
      this.selectionState = false;
      this.currentFillState = null;

      const changes = this.applyChanges();
      console.log('CHANGES IN RTE', changes);
      this.$emit('handsSelect', changes);
    },
    startSelection(hand) {
      this.selectionState = true;
      this.selectHand(hand);
    },
    selectHand(hand) {
      console.log('handselected', hand);
      if (this.selectionState && hand && this.isReadOnly == false) {
        console.log(
          'filltest: 0',
          hand,
          this.isActiveLevelMode,
          this.currentFillState
        );
        // this.$set(hand, 'level', 111);
        if (this.selectionMode == SELECTION_MODE_TEST) {
          if (this.currentFillState == true) {
            if (this.lastHandSelected === hand.name) return;
            console.log('filltest: 1', this.currentFillState);
            this.lastHandSelected = hand.name;
            hand.level = this.playLevel;
            hand.useForTest = !hand.useForTest;
          } else if (this.currentFillState == false) {
            console.log('filltest: 2');
            if (
              this.isActiveLevelMode &&
              this.compareLevel(hand.level, this.playLevel)
            ) {
              hand.useForTest = false;
            } else {
              hand.level = this.playLevel;
              this.$set(hand, 'weight', this.weight);
            }
          } else if (this.currentFillState == null) {
            console.log('filltest: 3');
            if (hand.useForTest == true) {
              console.log('filltest: 3.1');

              this.currentFillState = true;
            } else if (hand.useForTest == false) {
              console.log('filltest: 3.2');
              this.currentFillState = true;
            } else {
              console.log('filltest: 3.3');
              this.currentFillState = true;
            }
            console.log('filltest: 3.4');
            if (this.isActiveLevelMode) {
              console.log(hand.level, this.playLevel, 'compLevels');
              if (this.compareLevel(hand.level, this.playLevel)) {
                hand.useForTest = !hand.useForTest;
                console.log('filltest: 3.5');
              } else {
                if (this.currentFillState) {
                  hand.useForTest = this.currentFillState;
                  console.log('filltest: 3.6');
                }
                hand.level = this.playLevel;
                this.$set(hand, 'weight', hand.weight);
              }
            } else {
              console.log('filltest: 3.7');
              hand.useForTest = !hand.useForTest;
            }
          }
          // if (this.isActiveLevelMode) {
          //   hand.level = this.playLevel;
          // } else {
          //   hand.level = 0;
          // }
          this.recalculateState();
        } else if (this.selectionMode == SELECTION_MODE_WEIGHT && this.weight) {
          if (this.isActiveLevelMode) {
            hand.level = this.playLevel;
          } else {
            hand.level = 0;
          }

          if (this.currentFillState == true) {
            console.log('currentSelection: 1');
            this.$set(hand, 'weight', this.cloneWeight(this.weight));
            hand.useForTest = true;
          } else if (this.currentFillState == false) {
            this.$delete(hand, 'weight')
            if (this.compareWeights(this.weight, hand.weight)) {
              hand.useForTest = true;
            }

            console.log('currentSelection: 2');
          } else if (this.currentFillState == null) {
            console.log('currentSelection: 3', this.weight, hand.weight);

            if (this.compareWeights(this.weight, hand.weight)) {
              console.log('currentSelection: 4');
              this.currentFillState = false;
              this.$set(hand, 'weight', null);
            } else {
              console.log('currentSelection: 5');
              this.currentFillState = true;
              this.$set(hand, 'weight', this.weight);

              hand.useForTest = true;
            }
          }

          this.recalculateState();
        }
      }
    },
    emptyStatistics() {
      return { correct: 0, incorrect: 0 };
    },
    emptyWeight() {
      return this.buildWeight();
    },
    cloneWeight(weight) {
      return this.buildWeight(
        weight.fold,
        weight.check,
        weight.call,
        weight.raise,
        weight.allin
      );
    },
    buildWeight(fold, check, call, raise, allin) {
      return {
        fold: fold ?? 0,
        call: call ?? 0,
        check: check ?? 0,
        raise: raise ?? 0,
        allin: allin ?? 0
      };
    },
    compareLevel(level1, level2) {
      return level1 === level2;
    },
    //w1: weight, w2: weight
    compareWeights(w1, w2) {
      if (w1 == w2) {
        return true;
      } else {
        if (w1 == null || w2 == null) return false;

        return (
          w1.fold === w2.fold &&
          w1.check === w2.check &&
          w1.call === w2.call &&
          w1.raise === w2.raise &&
          w1.allin === w2.allin
        );
      }
    },
    isWeightValid(weight) {
      if (weight) {
        if (
          weight.fold > 0 ||
          weight.call > 0 ||
          weight.raise > 0 ||
          weight.allin > 0 ||
          weight.check > 0
        )
          return true;
      }
      return false;
    },
    //value: number
    formatStatisticsValue(value) {
      if (value >= 99) return 99;
      return value;
    },
    getHandByPosition(x, y) {
      let index = this.convertPositionToIndex(x, y);
      let hand = this.handsRange[index];
      console.log(hand, 'hand pos');
      return hand;
    },
    convertPositionToIndex(x, y) {
      let index = x * 13 + y;
      return index;
    },
    // hand: string (AKs, AA, 98o, ...)
    getHandPosition(hand) {
      let index = this.getHandIndex(hand);
      let x = parseInt(index / 13);
      let y = parseInt(index % 13);
      return { x, y };
    },
    // hand: string (AKs, AA, 98o, ...)
    getHandIndex(hand) {
      return SEQUENTIAL_HANDS_RANGE().indexOf(hand);
    },
    // recalculates table state (percent of raise/call/fold/allin actions)
    recalculateState() {
      let percent = GET_PERCENT_FROM_TABLE_DATA(this.handsRange);
      let count = GET_COUNT_FROM_TABLE_DATA(this.handsRange);

      let state = {
        ...percent,
        ...count
      };
      console.log(state, 'stateData');

      this.rangeState.fold = state.fold;
      this.rangeState.foldCount = state.foldCount;
      this.rangeState.check = state.check;
      this.rangeState.checkCount = state.checkCount;
      this.rangeState.call = state.call;
      this.rangeState.callCount = state.callCount;
      this.rangeState.raise = state.raise;
      this.rangeState.raiseCount = state.raiseCount;
      this.rangeState.allin = state.allin;
      this.rangeState.allinCount = state.allinCount;
      console.log(state, 'STATE CHANGED');
      this.$emit('stateChanged', state);
    },
    loadTableCells() {
      let source = SEQUENTIAL_HANDS_RANGE();
      let result = [];
      source.forEach(name => {
        let hand = {};
        hand.name = name;
        hand.useForTest = false;
        hand.level = 0;
        hand.correct = 0;
        hand.incorrect = 0;
        hand.time = 0;

        result.push(hand);
      });
      this.handsRange = result;
    },
    tickListener() {
      if (this.isReadOnly) return;
      if (this.selectionState) this.completeSelection();
    }
  },
  computed: {
    ...mapGetters({
      globalShortDeskFromStore: 'user/globalShortDesk', // Отримуємо з Vuex
    }),
    globalShortDesk() {
      return this.globalShortDeskFromStore || this.dataItem?.additionalProperties?.sdsek;
    },
    isHeroActive() {
      return this.activeParticipant === this.heroPosition;
    },
    rowIterator() {
      let rows = [];
      if (this.handsRange) {
        if (this.handsRange.length === 169) {
          for (let i = 0; i < 13; i++) {
            let start = i * 13;
            let end = i * 13 + 13;
            let row = this.handsRange.slice(start, end);
            rows.push(row);
          }
        }
      }
      console.log(rows, 'rows');
      return rows;
    },
  },

  mounted() {
    this.loadTableCells();
    // if (this.isAnimated) this.startAnimation();
    this.updateTable();

    this.weights = this.isInverse
      ? ['allin', 'raise', 'call', 'check', 'fold']
      : ['fold', 'check', 'call', 'raise', 'allin'];

    this.allPossibleWeights = this.weights;
    this.$nextTick(() => {
      document.addEventListener('mouseup', this.tickListener);
    });
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.tickListener);
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
    this.intervals.forEach(interval => {
      clearInterval(interval);
    });
  },
  watch: {
    isInverse() {
      // вотчер для инверсии, в будущем нужно будет сделать другую архитектуру, произвольную
      this.weights = this.isInverse
        ? ['allin', 'raise', 'call', 'check', 'fold']
        : ['fold', 'check', 'call', 'raise', 'allin'];
      this.allPossibleWeights = this.weights;
    },
    activeParticipant: {
      immediate: true,
      handler() {
        console.log(
          this.$props.activeParticipant,
          'ACTIVE TABLE PART FROM Range table editor'
        );
      }
    },
    hands: {
      immediate: true,
      deep: false,
      handler() {
        this.updateTable();
        this.getMaxLevel();
      }
    },
    statistics: {
      immediate: true,
      deep: false,
      handler() {
        this.updateTable();
      }
    }
  }
};
</script>

<style lang="scss" src="./RangeTableEditor.scss" />
