<template>
  <div class="weights-list">
    <div
      v-for="(weight, key) in computedWeights"
      :key="key"
      :class="{
        'weights-list__item--selected': selectedKey === weight.name
      }"
      class="weights-list__item"
      @click="onClickAction(weight.name)"
    >
      {{ weight.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeightsList',
  data() {
    return {
      selectedKey: null
    };
  },
  computed: {
    computedWeights() {
      /*eslint-disable*/
      // console.log(this.weights, 'start weights');
      let output = Object.keys(this.weights)
        .map(key => {
          // console.log(key, this.weights, 'weight slider weights');
          this.weights[key].name = key;

          return this.weights[key];
        })
        .filter(weight => weight.isAvailable);
      if (this.isInverse) return output.reverse();
      return output;
    }
  },
  props: {
    weights: Object,
    isInverse: Boolean
  },
  methods: {
    onClickAction(key) {
      if (this.selectedKey === key) {
        this.selectedKey = null;

        this.$emit('weightSelected', null);
      } else {
        this.selectedKey = key;
        this.$emit('weightSelected', key);
      }
    },
    reset() {
      this.selectedKey = null;
      this.$emit('weightSelected', null);
    }
  }
};
</script>

<style lang="scss" src="./WeightsList.scss" />
