import http from '../utils/http';

import { DEFAULT_PARENT_ID } from '@/constants/items';
import { ITEM_TYPES } from '@/constants/itemTypes';

export const GET_CHILDREN_OF_ITEM = async (id = DEFAULT_PARENT_ID, params = {}) => {
  let requestParameters = { params: params };
  const { data } = await http.get(`Items/${id}/Children`, requestParameters);
  return data;
};

export const GET_ITEM_RECURSIVELY = async (id = DEFAULT_PARENT_ID, params = {}) => {
  let requestParameters = { params: params };
  const { data } = await http.get(`Items/${id}/Children/Recursively`, requestParameters);
  return data;
};

// get tree full item
export const GET_ITEM = async (parentId = DEFAULT_PARENT_ID) => {
  const data = await GET_CHILDREN_OF_ITEM(parentId);

  const groups = data.filter(item => ITEM_TYPES[1] === item.type);
  const diapasons = data.filter(item => ITEM_TYPES[0] === item.type);

  return {
    groups,
    diapasons
  };
};

export const GET_ITEMS_STATISTICS = async userId => {
  let requestParameters = {
    params: {}
  };
  if (userId) requestParameters.params.userId = userId;
  const { data } = await http.get('Items/Statistics', requestParameters);
  return data;
};
export const UPDATE_ITEMS_STATISTICS = async (items, uuid) => {
  // console.log(items, uuid, 'UPDATE_ITEMS');
  await http.put('Items/Statistics', {
    sessionId: uuid,
    items
  });
};

export const GET_HANDS_STORIES = async ({ sessionId, itemId }) => {
  // console.log(sessionId, 'sesis');
  const requestParameters = {
    params: {
      session: sessionId,
      itemId: itemId
    }
  };
  const { data } = await http.get('Items/HandStories', requestParameters);
  // console.log(data, 'data hands stories');
  return data;
};

export const GET_ITEMS_HANDS = async (itemIDs, params) => {
  // console.log(itemIDs, params, 'GET_ITEMS_HANDS');
  let request = itemIDs.join(',');
  const { data } = await http.get(`Items/${request}/Hands`, {
    params: params
  });
  // console.log(data, 'DATA IN REQUEST');
  return data;
};

export const GET_LINKED_ITEMS_HANDS = async (itemIDs, params) => {
  // console.log(itemIDs, 'ITEM IDS IN LINKED');
  // let ids = [
  //   'cb55339f-1863-4f6a-9fff-d735cddae449',
  //   'cf03468e-93a5-4eaf-8cb7-dea7f41f1869',
  //   '6728e3cc-1181-4db4-8af9-193ef3d9d41c',
  //   '743671b9-3ac3-4603-b1d9-339866b6a666',
  //   'b0c8a1b8-13c3-4ce7-9344-3f1d3c0e5371',
  //   'a10f8762-349d-4fe2-b9f3-8cb0ff0a4d28',
  //   'aff8e27d-51ef-487d-9923-56d4f3ab0c56',
  //   '4a892144-bec6-486b-9699-e698b2d17532',
  //   '976e67e3-85cc-4459-89f2-6aeb3f07e191'
  // ];
  let request = itemIDs.join(',');
  const { data } = await http.get(`Items/${request}/Hands`, {
    params: params
  });
  // console.log(data, 'DATA IN REQUEST');
  return data;
};

export const GET_ITEMS_TREE = async itemIDs => {
  // console.log(itemIDs);

  let request = itemIDs.join(',');
  const { data } = await http.get(`Items/${request}/Tree`);
  return data;
};

export const GET_ITEMS_INFO = async itemIDs => {
  // console.log(itemIDs, 'ITEMIDS');
  const { data } = await http.get('Items/' + itemIDs + '/Info');
  return data;
};

export const GET_FULL_ITEM_INFO = async itemID => {
  const { data } = await http.get('Items/' + itemID);
  return data;
};

export const GET_ALL_ITEMS = async () => {
  const { groups, diapasons: diapasonsWithoutGroup } = await GET_ITEM();

  return {
    groups,
    diapasonsWithoutGroup
  };
};

export const GET_ALL_ITEMS_OLD = async () => {
  const { groups, diapasons: diapasonsWithoutGroup } = await GET_ITEM();

  const groupsItems = await Promise.all(
    groups.map(group => GET_ITEM(group.id))
  );
  const groupsDiapasons = groupsItems
    .map(item => item.diapasons)
    .reduce((acc, val) => [...acc, ...val], []);

  return {
    groups,
    diapasonsWithoutGroup,
    groupsDiapasons
  };
};

export const UPDATE_ITEM_RANGE = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .put('Items/Diapason', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const UPDATE_ITEM_GROUP = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .put('Items/Group', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const UPDATE_PREMIUM_ITEM_GROUP = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .put('Items/Group/Links', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const CLEAR_STATISTICS = async itemId => {
  const { data } = await http.put(`Items/${itemId}/Statistics/Clear`);
  return data;
};

export const INCLUDE_LINKED_GROUPS = async itemId => {
  const { data } = await http.get(
    `items/${itemId}/tree?includeLinkedGroups=false `
  );
  return data;
};

export const CREATE_GROUP = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .post('Items/Group', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const CREATE_RANGE = async DTO => {
  return new Promise((resolve, reject) => {
    http
      .post('Items/Diapason', DTO)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const DELETE_ITEMS = async itemsString =>
  await http.delete(`Items/${itemsString}`);
export const MOVE_ITEM = async DTO => await http.post('/Items/Move', DTO);

export const ITEMS_COPY = async (items, parentId, recursively) => {
  const { data } = await http.post('Items/Copy', {
    items: items,
    parentId: parentId,
    recursively: recursively
  });

  return data;
};
export const SEARCH_ITEMS = async params => {
  let itemId = DEFAULT_PARENT_ID

  if (params.parentItemId)
    itemId = params.parentItemId

  const data = await GET_CHILDREN_OF_ITEM(itemId, {
    userId: params.userId,
    isAvailable: params.isAvailable,
  });
  return data;
};
