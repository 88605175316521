<template>
  <div>
    <div class="user-ranges-checkbox">
      <input
        id="isAvailable"
        v-model="isAvailable"
        type="checkbox"
        @change="key++"
      />
      <label for="isAvailable">
        Is Available
      </label>
    </div>
    getItems: {{getItems}}
    <List
      :key="key"
      :children-provider="getItems"
      class="ranges-list__items"
      @currentItemChange="onCurrentItemChange"
    >
      <template v-slot:card-body-content="item">
        <div v-if="item.purchaseInfo !== null" class="purchased" />
      </template>
      <template v-slot:card-inner="item">
        <div class="list__item__card__label">
          {{ item.name }}
        </div>

        <div class="list__item__card__diapason__info">
          <div
            v-if="item.statistics.correct > 0 || item.statistics.incorrect > 0"
            class="list__item__card__diapason__info__answers"
          >
            <div class="incorrect">
              {{ item.statistics.incorrect }}
            </div>
            <div class="correct">
              {{ item.statistics.correct }}
            </div>
          </div>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import { SEARCH_ITEMS } from '@/api/items';
import List from '../UI/List/List';

export default {
  name: 'UserRangesList',
  components: { List },
  data() {
    return {
      key: 0,

      isAvailable: false,
      currentItem: {}
    };
  },
  props: {
    userId: String
  },
  methods: {
    onCurrentItemChange(value) {
      this.currentItem = value;
      this.$emit('currentItemChange', value);
    },

    async getItems(item) {
      const result = await SEARCH_ITEMS({
        userId: this.userId,
        isAvailable: this.isAvailable,
        parentItemId: item ? item.id : null
      });

      // console.log('getItems result:', result);

      return result;
    },
  }
};
</script>
<style lang="scss" scoped src="./UserRangesList.scss" />
