<template>
  <div v-if="loaded" class="premium-item-localization">
    <TabsNav v-model="activeTab">
      <TabsNavBtn
        v-for="lang in langs"
        :key="lang"
        class="premium-item-localization__tabs__item"
      >
        <input v-model="selectedLangs" :value="lang" type="checkbox" />
        {{ lang }}
      </TabsNavBtn>
    </TabsNav>
    <Tabs v-model="activeTab">
      <Tab v-for="lang in langs" :key="lang">
        <div class="premium-item-localization__field">
          <p>Name</p>
          <input
            v-model="localizationForModify[lang].name"
            class="form-input"
          />
        </div>
        <div class="premium-item-localization__field">
          <p>Short Desc</p>
          <input
            v-model="localizationForModify[lang].shortDescription"
            class="form-input"
          />
        </div>
        <div class="premium-item-localization__field">
          <p>Content</p>
          <markdown-editor
            v-model="localizationForModify[lang].content"
            :options="editorOptions"
            style="height: 600px;"
          />
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
// import { GET_LANGS } from '@/api/localizations';
import TabsNav from '../UI/Tabs/TabsNav';
import TabsNavBtn from '../UI/Tabs/TabsNavBtn';
import Tabs from '../UI/Tabs/Tabs';
import Tab from '../UI/Tabs/Tab';

export default {
  name: 'PremiumItemLocalizationsEditor',
  props: {
    itemLocalization: Object
  },
  data() {
    return {
      loaded: false,
      editorOptions: {
        lineNumbers: false,
        styleActiveLine: true,
        styleSelectedText: true,
        lineWrapping: false,
        indentWithTabs: false,
        tabSize: 2,
        indentUnit: 2
      },

      langs: [],
      selectedLangs: [],

      activeTab: 0,

      dataLocalization: {},
      localizationForModify: {}
    };
  },
  components: {
    Tab,
    Tabs,
    TabsNavBtn,
    TabsNav
  },
  methods: {
    saveLocalizations() {
      if (this.selectedLangs.length === 0) {
        this.dataLocalization = null;
        return {};
      }

      let selectedLangsLocalization = {};
      this.selectedLangs.forEach(lang => {
        selectedLangsLocalization[lang] = this.localizationForModify[lang];
      });

      this.dataLocalization = selectedLangsLocalization;

      return selectedLangsLocalization;
    },
    loadLocalizations() {
      this.langs = ['ru', 'eng', 'ua'];

      this.localizationForModify = {};
      this.selectedLangs = [];

      this.langs.forEach(lang => {
        if (
          !this.itemLocalization ||
          !this.itemLocalization.hasOwnProperty(lang)
        ) {
          this.localizationForModify[lang] = {
            name: '',
            shortDescription: '',
            content: ''
          };
        } else {
          this.localizationForModify[lang] = this.itemLocalization[lang];
          this.selectedLangs.push(lang);
        }
      });
      this.dataLocalization = this.localizationForModify;

      this.activeTab = 0;
      this.loaded = true;
    }
  },
  created() {},
  async mounted() {
    // this.langs = await GET_LANGS();
    this.langs = Object.keys(this.itemLocalization) || ['', ''];
    this.localizationForModify = this.itemLocalization;

    this.loadLocalizations();

    this.$nextTick(() => {
      const editorElement = this.$el.querySelector('.CodeMirror-measure');
      if (editorElement) {
        editorElement.style.visibility = 'visible';
      }
    });

  },
  watch: {
    itemLocalization() {
      console.log('fdf');
      this.activeTab = 0;
      this.loaded = false;
      this.loadLocalizations();
    }
  }
};
</script>
<style lang="scss" src="./PremiumItemLocalizationsEditor.scss" />
