<template>
  <div
    :class="[
      'range-editor-wrapper',
      {
        'range-editor-wrapper--is-blocked': isBlocked
      }
    ]"
  >
    <slot name="BeforeRangeOptions" />
    <Expander>
      <ItemRangeOptionsEditor
        v-if="isRange"
        ref="itemRangeOptionsEditor"
        :diapason="computedRange"
        :isReadOnly="isReadOnly"
        :item="rangeForItemOptionsEditor"
        :requestPending="updateItemRequestPending"
        @activePart="setActiveParticipant"
        @checkWeightToggle="onCheckWeightToggle"
        @btnAction="applyChanges"
        :isAdmin="true"
        :btnSaveDisabled="false"
        :btnSaveLoading="updateItemRequestPending"
        :btnSaveColor="actionSaveBtn.btnColor"
        :btnSaveLabel="actionSaveBtn.label"
        @heroPos="setHeroPos"
        @getTempParts="getTempParts"
        @updateItemOptions="onItemOptionsUpdate"
      />
<!--      <ItemGroupOptionsEditor-->
<!--        v-if="isGroup"-->
<!--        ref="itemGroupOptionsEditor"-->
<!--        :item="item"-->
<!--        :group="item"-->
<!--        :isReadOnly="isReadOnly"-->
<!--        :linkedItemsProvider="loadLinkedItems"-->
<!--        :requestPending="updateItemRequestPending"-->
<!--        :btnSaveDisabled="false"-->
<!--        :btnSaveLoading="updateItemRequestPending"-->
<!--        :btnSaveColor="actionSaveBtn.btnColor"-->
<!--        :btnSaveLabel="actionSaveBtn.label"-->
<!--        @linkedItemsChange="onLinkedItemsChange"-->
<!--        @updated="onValueUpdate"-->
<!--        @linkingModeToggle="onLinkingModeToggle"-->
<!--        @btnAction="applyChanges"-->
<!--        @updateItemOptions="onItemOptionsUpdate"-->
<!--      />-->

      <ItemGroupOptionsEditor
        v-if="isGroup"
        ref="itemGroupOptionsEditor"
        :item="item"
        :group="item"
        :isReadOnly="isReadOnly"
        :linkedItemsProvider="loadLinkedItems"
        :requestPending="updateItemRequestPending"
        :btnSaveDisabled="false"
        :btnSaveLoading="updateItemRequestPending"
        :btnSaveColor="actionSaveBtn.btnColor"
        :btnSaveLabel="actionSaveBtn.label"
        @linkedItemsChange="onLinkedItemsChange"
        @updated="onValueUpdate"
        @linkingModeToggle="onLinkingModeToggle"
        @btnAction="applyChanges"
        @updateItemOptions="onItemOptionsUpdate"
      />
    </Expander>
    <Expander>
      <ExpanderItem>
        <template v-slot:header>
          Localization
        </template>
        <template v-slot:content>
          <PremiumItemLocalizationsEditor
            v-if="!isNullItem"
            ref="PremiumItemLocalizationsEditor"
            :itemLocalization="item.localization"
          />
        </template>
      </ExpanderItem>
    </Expander>
    <Expander>
      <ExpanderItem>
        <template v-slot:header>
          Availability Layer
        </template>
        <template v-slot:content>
          <PremiumItemAvailabilityEditor
            v-if="!isNullItem"
            ref="PremiumItemAvailabilityEditor"
            :layer="item.availabilityLayer"
            :price="item.price"
            :price-modifier="item.priceModifier"
          />
        </template>
      </ExpanderItem>
    </Expander>

    <Expander>
      <ExpanderItem>
        <template v-slot:header>
          Store product descriptors
        </template>
        <template v-slot:content>
          <StoreProductDescriptorEditor
            v-if="!isNullItem"
            ref="StoreProductDescriptorEditor"
            :descriptors="item.storeProductDescriptors"
          />
        </template>
      </ExpanderItem>
    </Expander>

    <div class="range-table-editor__actions">
      <div class="range-table-editor__actions__inputs">
        <RadioGroup
          v-model="selectionMode"
          class="range-table-editor__actions__radio-btns"
        >
          <Radio
            v-for="option in options"
            :key="option.label"
            :label="option.label"
            :value="option.value"
            class="range-table-editor__actions__radio-btns__item"
          />
        </RadioGroup>
        <Checkbox
          :style="{ whiteSpace: 'nowrap' }"
          v-model="levelMode"
          :label="localization.general.MainPage.LevelMode"
        />

        <transition name="fade">
          <LevelsField
            v-if="levelMode"
            :maxLevel="maxLevel"
            @getActiveLevel="onGetActiveLevel"
          />
        </transition>

        <div class="range-table-editor__actions__checkboxes">
          <Checkbox
            v-model="showStatistics"
            :label="localization.general.MainPage.ShowStatistics"
          />
        </div>
      </div>
      <div class="range-table-editor__actions__buttons">
        <DropdownMenu
          :header-label="localization.general.MainPage.Table"
          :items="menuItemsForTable"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
        <DropdownMenu
          :header-label="localization.general.MainPage.Clear"
          :items="clearMenuItems"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
      </div>
    </div>

    <WeightSlider
      v-if="isRange"
      ref="weightsSlider"
      :weight="weightsSlider"
      @weightSliderChanged="onWeightSliderChange"
      @click.native="resetSliderRange()"
      :isInverse="isInverse"
    />
    <WeightsList
      v-if="isRange"
      ref="weightsList"
      :weights="weights"
      @weightSelected="weightSelected"
      :isInverse="isInverse"
    />

    <slot name="AfterRangeOptions" />
    <slot name="BeforeTable" />

<!--    <RangeTableEditor-->
<!--      ref="rangeTableEditor"-->
<!--      :actionColors="actionColors"-->
<!--      :hands="computedRangeHands"-->
<!--      :is-read-only="isGroup || isReadOnly"-->
<!--      :range="computedRangeInfo"-->
<!--      :selectionMode="selectionMode"-->
<!--      :heroPosition="heroPosition"-->
<!--      :weight="computedSelectedWeights"-->
<!--      @handsSelect="onHandsSelect"-->
<!--      :activeParticipant="activeParticipant"-->
<!--      :isInverse="isInverse"-->
<!--      :isActiveLevelMode="levelMode"-->
<!--      :playLevel="activeLevel"-->
<!--      :isRange="isRange"-->
<!--      @changeMaxLevel="onChangeMaxLevel"-->
<!--      @scrollChanges="scrollChanges"-->
<!--    />-->

    <RangeTableEditor
      ref="rangeTableEditor"
      :actionColors="actionColors"
      :hands="computedRangeHands"
      :is-read-only="
        isGroup || isReadOnly
      "
      :selectionMode="selectionMode"
      :heroPosition="heroPosition"
      :showStatistics="showStatistics"

      :statistics="statistics"
      :weight="computedSelectedWeights"

      :isRange=isRange
      :playLevel=activeLevel

      @clearStatistic="onClearStatistics"
      @handsSelect="onHandsSelect"
      @changeMaxLevel="onChangeMaxLevel"

      :activeParticipant="activeParticipant"
      :isActiveLevelMode="levelMode"
      :inverseOrder="$store.getters['user/actionsOrder']"
      :isInverse="isInverse"

      @scrollChanges="scrollChanges"

      :statisticsOfGroup="statisticsOfGroup"
    />

    <slot name="AfterTable" />

    <Slider
      ref="rangeSlider"
      :is-read-only="!isRange"
      :maxValue="169"
      :minValue="0"
      :step="0"
      :value="0"
      @rangeSliderChange="onRangeSliderChange"
    />

    <RangeStringParser
      :disabled="disableRangeStringParser"
      @rangeStringParse="onRangeStringParse"
    />

    <!-- <div class="range-editor__actions">
      <button class="range-editor__actions__btn-save" @click="applyChanges">
        {{ localization.general.MainPage.SaveDiapasonChanges }}
      </button>
    </div> -->
  </div>
</template>

<script>
import {
  GET_PREMIUM_ITEMS_INFO,
  UPDATE_PREMIUM_ITEM_DIAPASON,
  UPDATE_PREMIUM_ITEM_GROUP
} from '@/api/premiumItems';

import LevelsField from '@/components/LevelsField';
import _ from 'lodash';
import StoreProductDescriptorEditor from '@/components/StoreProductDescriptorEditor';
import PremiumItemAvailabilityEditor from '@/components/PremiumItemAvailabilityEditor';
import PremiumItemLocalizationsEditor from '@/components/PremiumItemLocalizationsEditor';

import RangeEditorMixin from '../RangeEditorMixin';
import ItemGroupOptionsEditor from '@/components/ItemOptionsEditor/ItemGroupOptionsEditor';


export default {
  name: 'PremiumRangeEditor',
  mixins: [RangeEditorMixin],
  components: {
    ItemGroupOptionsEditor,
    PremiumItemLocalizationsEditor,
    PremiumItemAvailabilityEditor,
    StoreProductDescriptorEditor,
    LevelsField
  },
  data() {
    return {
      statistics: {
        hands: {}
      },
      selectedWeights:{ "fold": 0.25, "check": 0, "call": 0.25, "raise": 0.25, "allin": 0.25 },
      testLocale: {
        ru: {
          name: 'ru',
          shortDescription: 'Russian',
          content: 'Russsian'
        },
        ua: {
          name: 'ua',
          shortDescription: 'Ukrainian',
          content: 'Ukrainian'
        }
      }
    };
  },

  computed: {},
  async mounted() {
    await this.$store.dispatch('user/LOAD_GLOBAL_PREFERENCES');
  },

  methods: {
    async loadLinkedItems(itemIDs) {
      return await GET_PREMIUM_ITEMS_INFO(itemIDs);
    },
    async applyChangesToGroup() {
      // console.log('Start save premium group');
      const itemGroupOptionsEditor = this.$refs.itemGroupOptionsEditor;
      const appliedChangesToGroup = itemGroupOptionsEditor.submit();

      const appliedChangesToLocalizations = this.$refs.PremiumItemLocalizationsEditor.saveLocalizations();
      const appliedChangesToAvailabilityEditor = this.$refs.PremiumItemAvailabilityEditor.getChanges();
      const appliedChangesToDescriptorEditor = this.$refs.StoreProductDescriptorEditor.buildDescriptors();

      // const linkedItemsExist = appliedChangesToGroup.data.linkedItems;
      // const linksGroup = linkedItemsExist
      //   ? {
      //       items: appliedChangesToGroup.linkedItems
      //     }
      //   : null;

      const linksGroup = appliedChangesToGroup.data.linkedItems
        ? {
            items: appliedChangesToGroup.data.linkedItems
          }
        : null;

      return new Promise((resolve, reject) => {
        // if (!appliedChangesToGroup.validated) {
        //   reject('NotValidated');
        //   return;
        // }

        UPDATE_PREMIUM_ITEM_GROUP({
          name: appliedChangesToGroup.data.name,
          linksGroup: linksGroup,
          storeProductDescriptors: appliedChangesToDescriptorEditor,
          localization: appliedChangesToLocalizations,
          availabilityLayer: appliedChangesToAvailabilityEditor.layer,
          priceModifier: appliedChangesToAvailabilityEditor.priceModifier,
          price: appliedChangesToAvailabilityEditor.price,
          id: this.item.id,
          parentId: this.item.parentId
        })
          .then(() => {
            this.item.name = appliedChangesToGroup.data.name;
            this.item.linksGroup = linksGroup;

            this.item.priceModifier =
              appliedChangesToAvailabilityEditor.priceModifier;
            this.item.availabilityLayer =
              appliedChangesToAvailabilityEditor.layer;
            this.item.price = appliedChangesToAvailabilityEditor.price;
            this.item.priceModifier =
              appliedChangesToAvailabilityEditor.priceModifier;
            this.item.localization = appliedChangesToLocalizations;

            this.item.storeProductDescriptors = appliedChangesToDescriptorEditor;

            itemGroupOptionsEditor.turnOffLinkingMode();

            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    applyChangesToRange() {
      const appliedChangesToLocalizations = this.$refs.PremiumItemLocalizationsEditor.saveLocalizations();
      const appliedChangesToAvailabilityEditor = this.$refs.PremiumItemAvailabilityEditor.getChanges();
      const appliedChangesToDescriptorEditor = this.$refs.StoreProductDescriptorEditor.buildDescriptors();
      const appliedChangesToCurrentItem = this.$refs.rangeTableEditor.applyChanges();
      const appliedChangesToDiapason = this.$refs.itemRangeOptionsEditor.submit();

      let tempPartsToMerge = this.tempParts;
      let tempHandsToMerge = this.tempDiapason;

      const deepMergeHands = (parts, hands) => {
        let finalMerged = {};
        for (let key in parts) {
          finalMerged[key] = _.merge(parts[key], hands[key]);
        }
        return finalMerged;
      };

      let mergedHands = deepMergeHands(tempPartsToMerge, tempHandsToMerge);

      return new Promise((resolve, reject) => {
        // if (
        //   appliedChangesToCurrentItem.validated &&
        //   appliedChangesToDiapason.validated
        // ) {
        //   reject('NotValidated');
        //   return;
        // }

        UPDATE_PREMIUM_ITEM_DIAPASON({
          name: appliedChangesToDiapason.data.name,
          additionalProperties:
            appliedChangesToDiapason.data.additionalProperties,
          tableFormat: appliedChangesToDiapason.data.tableFormat,
          participants: mergedHands,

          storeProductDescriptors: appliedChangesToDescriptorEditor,
          localization: appliedChangesToLocalizations,
          availabilityLayer: appliedChangesToAvailabilityEditor.layer,
          priceModifier: appliedChangesToAvailabilityEditor.priceModifier,
          price: appliedChangesToAvailabilityEditor.price,
          id: this.item.id,
          parentId: this.item.parentId,
          statistics: appliedChangesToCurrentItem.data.statistics,
          hands: appliedChangesToCurrentItem.data.hands,
          weights: []
        })
          .then(() => {
            this.item.priceModifier =
              appliedChangesToAvailabilityEditor.priceModifier;

            this.item.availabilityLayer =
              appliedChangesToAvailabilityEditor.layer;

            this.item.price = appliedChangesToAvailabilityEditor.price;

            this.item.localization = appliedChangesToLocalizations;
            this.item.storeProductDescriptors = appliedChangesToDescriptorEditor;
            this.item.statistics = appliedChangesToCurrentItem.data.statistics;

            this.item.diapason.hands = appliedChangesToCurrentItem.data.hands;
            this.item.diapason.participants = mergedHands;
            this.item.diapason.additionalProperties =
              appliedChangesToDiapason.data.additionalProperties;

            resolve(true);
          })
          .catch(error => {
            reject(error);
          });

        this.$emit('currentItemChange', appliedChangesToAvailabilityEditor);
      });
    },
  }
};
</script>

<style lang="scss" src="./PremiumRangeEditor.scss" />
