import { mapGetters } from 'vuex';
import Slider from '@/components/RangeSlider';
import WeightSlider from '@/components/WeightSlider';
import WeightsList from '@/components/WeightsList';
import OptionsSelector from '@/components/UI/OptionsSelector';
import { Expander, ExpanderItem } from '@/components/UI/Expander';
import RangeTableEditor from '@/components/RangeTableEditor';
import RangeStringParser from '@/components/RangeStringParser';
import RangeWarning from '@/components/RangeWarning';

import ItemGroupOptionsEditor from '@/components/ItemOptionsEditor/ItemGroupOptionsEditor';
import ItemRangeOptionsEditor from '@/components/ItemOptionsEditor/ItemRangeOptionsEditor';

import itemsMixin from '@/mixins/itemsMixin';
import { Radio, RadioGroup } from '@/components/UI/Radio';
import Checkbox from '@/components/UI/Checkbox/Checkbox';
import DropdownMenu from '@/components/UI/DropdownMenu/DropdownMenu';
import RangeSlider from '@/components/RangeSlider/RangeSlider';
import deepClone from '@/utils/dataManipulations';
import { ITEM_TYPE_GROUP } from '@/constants/itemTypes';
import rangeHasCheck from '@/utils/itemRange';
import _ from 'lodash';

export default {
  mixins: [itemsMixin],

  components: {
    Radio,
    RadioGroup,
    Checkbox,
    DropdownMenu,
    RangeSlider,
    ItemGroupOptionsEditor,
    ItemRangeOptionsEditor,
    RangeWarning,
    RangeStringParser,
    RangeTableEditor,
    ExpanderItem,
    Expander,
    OptionsSelector,
    WeightsList,
    WeightSlider,
    Slider
  },
  data() {
    return {
      SELECTION_MODE_TEST: 'test',
      SELECTION_MODE_WEIGHT: 'weight',
      selectionMode: 'weight',

      updateItemRequestPending: false,
      rangeHands: {},
      selectedHands: {},
      isCopyExists: false,
      tempParts: {},
      levelMode: true,
      maxLevel: 1,
      activeLevel: 1,
      heroPosition: '',
      activeParticipant: null,
      updateAvailable: false,
      isInverse: false,

      tempDiapason: {},

      linkingMode: false,

      selectedWeight: null,
      showStatistics: true,
      defaultWeightsSlider: {
        fold: {
          isAvailable: true,
          value: 25,
          color: null
        },
        check: {
          isAvailable: false,
          value: 0,
          color: null
        },
        call: {
          isAvailable: true,
          value: 25,
          color: null
        },
        raise: {
          isAvailable: true,
          value: 25,
          color: null
        },
        allin: {
          isAvailable: true,
          value: 25,
          color: null
        }
      },
      weightsSlider: {
        fold: {
          isAvailable: true,
          value: 25,
          color: null
        },
        check: {
          isAvailable: false,
          value: 0,
          color: null
        },
        call: {
          isAvailable: true,
          value: 25,
          color: null
        },
        raise: {
          isAvailable: true,
          value: 25,
          color: null
        },
        allin: {
          isAvailable: true,
          value: 25,
          color: null
        }
      },
      weights: {
        fold: {
          isAvailable: true,
          color: null
        },
        check: {
          isAvailable: false,
          color: null
        },
        call: {
          isAvailable: true,
          color: null
        },
        raise: {
          isAvailable: true,
          color: null
        },
        allin: {
          isAvailable: true,
          color: null
        }
      }
    };
  },

  props: {
    rangesOfGroup: Array,
    item: Object,
    isBlocked: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    let value = this.$store.state.user.actionColors;
    let allInPositon = this.$store.state.user.actionsOrder.allin ?? false;

    this.isInverse = allInPositon === 0 ? true : false;
    console.log(value, 'VALUE SET WEIGHT');
    this.setWeightColors(this.defaultWeightsSlider, value);
    this.setWeightColors(this.weightsSlider, value);
    this.setWeightColors(this.weights, value);
    this.$emit('onUpdateItemRequestPending', this.updateItemRequestPending);
  },
  computed: {
    ...mapGetters({
      actionColors: 'user/actionColors',
      actionsOrder: 'user/actionsOrder',
      playGameOptions: 'play/getGameOptions',
    }),
    storedLevelModeOption() {
      return this.playGameOptions.find(
          option => option.id === 'levelMode'
      )?.value
    },
    actionSaveBtn() {
      let output = {};
      if (this.updateAvailable) {
        output = {
          label: this.localization.general.MainPage.SaveDiapasonChanges,
          handler: this.applyChanges,
          btnColor: 'red'
        };
      } else {
        output = {
          label: this.localization.general.MainPage.SaveDiapasonChanges,
          handler: this.applyChanges,
          btnColor: 'red'
        };
      }

      return output;
    },
    itemHasSharingId() {
      if (!this.item.sharing) return false;
      if (this.item.sharing.FromItemId) return true;
      if (!this.item.sharing.FromItemId) return false;
    },
    action() {
      let output = {};
      if (this.updateAvailable) {
        output = {
          label: this.localization.general.MainPage.SaveDiapasonChanges,
          handler: this.applyChanges,
          btnColor: 'red'
        };
      } else {
        output = {
          label: this.localization.general.MainPage.StartTest,
          handler: this.startTest,
          btnColor: 'blue'
        };
      }

      this.$emit('handleActionButton', output);
      return output;
    },
    handsExistInClipboard() {
      return this.isCopyExists;
      // return this.$session.exists('handsOnClipboard');
    },
    rangeForItemOptionsEditor() {
      if (!this.isRange) return null;
      return {
        name: this.item.name,
        type: this.item.type,
        participants: this.item.diapason.participants,
        additionalProperties: this.item.diapason.additionalProperties,
        linksGroup: this.item.linksGroup,
        linkedItems: []
      };
    },

    groupForItemOptionsEditor() {
      if (!this.isGroup) return null;

      return {
        name: this.item.name,
        type: this.item.type,
        linksGroup: this.item.linksGroup,
        linkedItems: []
      };
    },

    menuItemsForTable() {
      return [
        {
          label: this.localization.general.MainPage.CopyButtonTable,
          icon: '/icons/copy.svg',
          handler: this.copyTable,
          disabled: this.isGroup
        },
        {
          label: this.localization.general.MainPage.PasteButton,
          icon: '/icons/paste.svg',
          handler: this.pasteTable,
          disabled: !this.handsExistInClipboard
        }
      ];
    },
    clearMenuItemsGroup() {
      return [
        {
          label: this.localization.general.MainPage.ClearStatistic,
          icon: '/icons/statistics.svg',
          handler: this.clearStatistics,
          async: true,
          disabled: !this.editableItemSelected
        }
      ];
    },
    clearMenuItems() {
      return [
        {
          label: this.localization.general.MainPage.ClearWeights,
          icon: '/icons/weights.svg',
          handler: this.clearWeights,
          disabled: !this.editableRangeSelected
        },
        {
          label: this.localization.general.MainPage.ClearHands,
          icon: '/icons/hands.svg',
          handler: this.clearSelectedHands,
          disabled: !this.editableRangeSelected
        },
        {
          label: this.localization.general.MainPage.ClearStatistic,
          icon: '/icons/statistics.svg',
          handler: this.clearStatistics,
          async: true,
          disabled: !this.editableItemSelected || !this.isHeroActive
        }
      ];
    },
    editableItemSelected() {
      return this.itemIsSelected && !this.isReadOnly;
    },
    editableRangeSelected() {
      return this.isRange && this.editableItemSelected;
    },
    editableGroupSelected() {
      return this.isGroup && this.editableItemSelected;
    },
    itemHasPurchaseInfo() {
      return this.item?.purchaseInfo !== null;
    },
    itemHasActiveParticipant() {
      return this.activeParticipant !== null;
    },
    disableRangeStringParser() {
      return !this.isRange || !this.checkIfWeightSelected || this.isReadOnly;
    },
    rangesOfGroupExist() {
      return this.rangesOfGroup.length > 0;
    },
    computedRangeHands() {
      if (this.isNullItem) return {};
      if (!this.itemHasActiveParticipant) return {};
      console.log(
        this.rangeHands,
        this.groupHandsRange,
        'COMPUTED RANGE HANDS'
      );
      return this.isGroup ? this.groupHandsRange.hands : this.rangeHands;
    },
    computedRangeStatistics() {
      if (this.isNullItem) return;
      //TODO: Task HandsStories: reformat here
      return this.isGroup
        ? this.item.diapason.statistics ?? {}
        : this.item.diapason.statistics ?? {};
    },
    checkIfWeightSelected() {
      if (this.selectedWeight && this.selectedWeight !== 'fold') return true;

      /* CHEСK IF WEIGHT SLIDER HAS VALUE === 100 */
      for (let key in this.weightsSlider) {
        if (this.weightsSlider[key].value === 100 && key !== 'fold')
          return true;
      }

      return false;
    },
    computedSelectedWeights() {
      let valuesOfWeights = this.weightsSlider;
      let weightsWithValue = {};
      if (this.selectedWeight) {
        for (let key in valuesOfWeights) {
          if (key === this.selectedWeight) {
            weightsWithValue[key] = 1;
          } else {
            weightsWithValue[key] = 0;
          }
        }
      } else {
        for (let key in valuesOfWeights) {
          weightsWithValue[key] = valuesOfWeights[key].value / 100;
        }
      }
      return weightsWithValue;
    },
    groupHandsRange() {
      let hands = {};
      let stats = {};
      let correctAnswers = 0;
      let incorrectAnswers = 0;

      this.rangesOfGroup.forEach(range => {
        if (range.diapason?.hands) {
          for (let key in range.diapason.hands) {
            let hand = range.diapason.hands[key];
            if (!hands.hasOwnProperty(key) && hand.useForTest) {
              hands[key] = {
                allin: 0,
                call: 0,
                fold: 0,
                raise: 0,
                useForTest: true
              };
            }
          }
        }
        if (range.diapason?.statistics?.hands) {
          for (let key in range.diapason.statistics.hands) {
            let hand = range.diapason.statistics.hands[key];
            if (!stats.hasOwnProperty(key)) {
              stats[key] = {
                correct: 0,
                incorrect: 0
              };
            }
            stats[key].correct += hand.correct;
            stats[key].incorrect += hand.incorrect;
            correctAnswers += hand.correct;
            incorrectAnswers += hand.incorrect;
          }
        }
      });

      // console.log(hands, 'ldlflsdflsd');

      return {
        hands,
        statistics: { correctAnswers, incorrectAnswers, hands: stats }
      };
    },
    options() {
      return [
        {
          value: this.SELECTION_MODE_WEIGHT,
          label: this.localization.general.MainPage.FillingWeights
        },
        {
          value: this.SELECTION_MODE_TEST,
          label: this.localization.general.MainPage.SelectingHandsForTest
        }
      ];
    },
    itemIsSelected() {
      return this.currentItem !== null;
    }
  },
  methods: {
    onChangeMaxLevel(level) {
      this.maxLevel = level;
    },
    onGetActiveLevel(level) {
      this.activeLevel = level;
    },
    onClearStatistics() {
      this.$emit('clearMainStatItem');
      // this.item.diapason.statistics.hands.forEach(hand => {
      //   hand.correct = 0;
      //   hand.incorrect = 0;
      // });
    },
    onValueUpdate() {
      console.log('UPDAVAILB onValueUpdate');
      this.updateAvailable = true;
    },
    async runAction(isLastDiapasonSave = false) {
      console.log('ds');

      this.action
        .handler()
        .then(() => {
          this.updateAvailable = false;
        })

        //здесь перейти на некст диапазон
        .then(() => console.log('isdone before'))
        .then(() => {
          if (isLastDiapasonSave) {
            this.$emit('openNextItem');
          }
        })
        .then(() => console.log('isdone after'))
        .catch(error => {
          console.log(error);
        });
    },
    setActiveParticipant(val) {
      if (this.activeParticipant === val) return;
      this.activeParticipant = val;
    },
    scrollChanges(hands) {
      console.log(hands, 'HANDZ coming');

      this.tempDiapason[this.activeParticipant].hands = hands;
    },
    setHeroPos(val) {
      this.heroPosition = val;
      let tempHeroObj = {};
      tempHeroObj[this.item.id] = val;
      console.log(typeof val, val, 'HERO POSITION FROM DEFAULT');
      // this.checkWeightToggle(val, true);
      // this.onCheckWeightToggle({ setCheck: true });
      this.$emit('setActiveParticipantsToPlay', tempHeroObj);
    },
    fillTempDiapason(val) {
      this.tempDiapason = {};
      for (let key in val) {
        this.$set(
          this.tempDiapason,
          key,
          _.cloneDeep(_.pick(val[key], ['hands']))
        );
      }
    },
    getTempParts(val) {
      // this.updateAvailable = true;
      console.log(val, 'value tempparts');
      this.tempParts = val;
    },
    changeRangeTable(value, isClearSelector = true) {
      console.log('check error', this.tempDiapason);
      let isObjectHasProperties = Object.keys(this.tempDiapason).length;
      if (!isObjectHasProperties) return;

      this.rangeHands = isObjectHasProperties
        ? deepClone(this.tempDiapason[value]?.hands)
        : {};

      console.log(isClearSelector, 'IS CLEAR SELECTOR??');
      let weightsSlider = deepClone(this.defaultWeightsSlider);

      let additionalProperties = this?.item.diapason.additionalProperties;

      console.log(additionalProperties, 'approp');
      if (
        rangeHasCheck(
          additionalProperties.type,
          this.heroPosition,
          additionalProperties.n
        )
      ) {
        weightsSlider.check.isAvailable = true;
        weightsSlider.call.isAvailable = false;
        weightsSlider.fold.isAvailable = false;

        this.weights.check.isAvailable = true;
        this.weights.call.isAvailable = false;
        this.weights.fold.isAvailable = false;

        weightsSlider.check.value = 34;
        weightsSlider.raise.value = 33;
        weightsSlider.allin.value = 33;
        weightsSlider.call.value = 0;
        weightsSlider.fold.value = 0;
      } else {
        weightsSlider.check.isAvailable = false;
        weightsSlider.call.isAvailable = true;
        weightsSlider.fold.isAvailable = true;

        this.weights.check.isAvailable = false;
        this.weights.call.isAvailable = true;
        this.weights.fold.isAvailable = true;

        weightsSlider.check.value = 0;
        weightsSlider.call.value = 25;
        weightsSlider.fold.value = 25;
      }
      if (isClearSelector) this.weightsSlider = weightsSlider;
      console.log(this.$refs, 'THis . REFSss');
      if (this.$refs.rangeSlider && isClearSelector) {
        this.$refs.rangeSlider.reset();
      }

      if (isClearSelector && this.$refs.weightsList) {
        this.$refs.weightsList.reset();
      }

      // console.log(this.rangeHands, 'rh af chRTbl');
    },
    passLinkedItems(items, type) {
      if (type === ITEM_TYPE_GROUP)
        this.$refs.itemGroupOptionsEditor.linkItems(items);
      else
        this.$refs.itemRangeOptionsEditor.linkItems(items);
    },
    onLinkedItemsChange(items) {
      // this.updateAvailable = true;
      this.$emit('linkedItemsChange', items);
    },
    onLinkingModeToggle(value) {
      this.linkingMode = value;
      this.$emit('linkingModeToggle', value);
    },
    async onItemOptionsUpdate() {
      await this.applyChanges();
    },
    onCheckWeightToggle(value) {
      let newHands = deepClone(this.rangeHands);

      console.log(value, newHands, 'onCheckWeightToggle');
      // this.fillTempDiapason(value);
      if (value.setCheck) {
        console.log('SETCHECK 1');
        Object.values(newHands).forEach(hand => {
          console.log(hand, 'hand setcheck1');

          hand.call = 0;
          hand.fold = 0;
        });
        this.rangeHands = newHands;

        this.weights.check.isAvailable = true;
        this.weights.call.isAvailable = false;
        this.weights.fold.isAvailable = false;

        this.weightsSlider.check.isAvailable = true;
        this.weightsSlider.fold.isAvailable = false;
        this.weightsSlider.call.isAvailable = false;

        this.weightsSlider.check.value =
          this.weightsSlider.call.value + this.weightsSlider.fold.value;

        this.weightsSlider.call.value = 0;
        this.weightsSlider.fold.value = 0;
      } else {
        console.log('SETCHECK 2');
        // Object.values(newHands).forEach(hand => {
        //   let half = hand.check / 2;
        //   hand.call = half;
        //   hand.fold = half;
        //   hand.check = 0;
        // });
        // this.rangeHands = newHands;

        this.weights.check.isAvailable = false;
        this.weights.fold.isAvailable = true;
        this.weights.call.isAvailable = true;

        this.weightsSlider.check.isAvailable = false;
        this.weightsSlider.fold.isAvailable = true;
        this.weightsSlider.call.isAvailable = true;

        const checkWeightHalf = this.weightsSlider.check.value / 2;
        this.weightsSlider.call.value = checkWeightHalf;
        this.weightsSlider.fold.value = checkWeightHalf;
        this.weightsSlider.check.value = 0;

        let weightsSlider = deepClone(this.defaultWeightsSlider);

        this.weightsSlider = weightsSlider;
      }
      console.log(this.$refs.weightsSlider, 'ref WeightSlider');
      this.$refs.weightsSlider.loadValues(this.weightsSlider);
      this.$refs.weightsList.reset();
      this.$refs.rangeTableEditor.updateTable();
      console.log('resz');
    },

    onHandsSelect(value) {
      console.log('UPDAVAILB onHandsSelect', value);
      console.log(
        this.tempDiapason[this.activeParticipant].hands,
        ' premium debug'
      );
      this.updateAvailable = true;
      this.rangeHands = value.data.hands;
      this.tempDiapason[this.activeParticipant].hands = this.rangeHands;
      console.log(this.rangeHands, 'RANGE HANDS AFTER SELECT');
    },
    onRangeStringParse(value) {
      console.log(value, 'value on parse');
      this.$refs.rangeTableEditor.fillWeights(value);
    },
    weightSelected(value) {
      this.selectedWeight = value;
    },
    onRangeSliderChange(value) {
      this.updateAvailable = true;
      this.$refs.rangeTableEditor.setHandsRangeSize(value);
    },
    optionChanged(selected) {
      this.selectionMode = selected;
      this.$refs.rangeSlider.reset();
    },
    resetSliderRange() {
      if (this.isRange) this.$refs.weightsList.reset();
    },

    onWeightSliderChange(value) {
      this.weightsSlider = value;
      this.$refs.weightsList.reset();
    },
    clearStatistics() {
      console.log('UPDAVAILB clearStatistics');
      this.$refs.rangeTableEditor.clearStatistics();

      this.updateAvailable = true;
    },
    clearWeights() {
      console.log('UPDAVAILB clearWeights');
      this.$refs.rangeSlider.reset();
      this.$refs.rangeTableEditor.clearWeights();

      this.updateAvailable = true;
    },
    clearSelectedHands() {
      console.log('UPDAVAILB clearSelectedHands');
      this.$refs.rangeTableEditor.clearTestHands();
      this.$refs.rangeSlider.reset();

      this.updateAvailable = true;
    },
    pasteTable() {
      if (!this.handsExistInClipboard) return;
      console.log('UPDAVAILB pasteHands');
      this.rangeHands = JSON.parse(this.$session.get('handsOnClipboard'));
      this.tempDiapason[this.activeParticipant].hands = JSON.parse(
        this.$session.get('handsOnClipboard')
      );
      console.log(this.tempDiapason, 'temphands on paste');
      this.updateAvailable = true;
    },
    copyTable() {
      this.isCopyExists = true;

      this.$session.set('handsOnClipboard', JSON.stringify(this.rangeHands));
    },

    setWeightColors(weights, value) {
      console.log(weights, 'WEIGHTS IN SET', value, 'VALUE IN SET');
      weights.allin.color = value.allin;
      weights.call.color = value.call;
      weights.fold.color = value.fold;
      weights.raise.color = value.raise;
      weights.check.color = value.check;
    },
    clearTable() {
      if (this.selectionMode === this.SELECTION_MODE_TEST) {
        this.$refs.rangeTableEditor.clearTestHands();
      } else if (this.selectionMode === this.SELECTION_MODE_WEIGHT) {
        this.$refs.rangeSlider.reset();
        this.$refs.rangeTableEditor.clearWeights();
      }
    },
    async applyChanges() {
      this.updateItemRequestPending = true;

      let request = this.isGroup
        ? this.applyChangesToGroup
        : this.applyChangesToRange;
      return new Promise((resolve, reject) => {
        request()
          .then(() => {
            this.$toast.success(
              this.localization.general.Alerts.SuccessfullySaved
            );
            this.updateAvailable = false;

            resolve(true);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            this.updateItemRequestPending = false;
          });
      });
    }
  },

  watch: {
    isHeroActive(newVal, oldVal) {
      console.log(newVal, oldVal, 'values on change gero');
      if (!newVal) {
        this.levelMode = false;
        if (this.isGroup) {
          this.showStatistics = true;
        } else {
          this.showStatistics = false;
        }

        this.selectionMode = this.SELECTION_MODE_WEIGHT;
        this.$refs.RadioGroup.setValue(this.SELECTION_MODE_WEIGHT);
      } else {
        this.levelMode = true;
        this.showStatistics = true;
        this.selectionMode = this.SELECTION_MODE_WEIGHT;
        this.$refs.RadioGroup.setValue(this.SELECTION_MODE_WEIGHT);
      }
    },
    rangeHands() {
      console.log(this.rangeHands, 'rangeHands sideEffects check');
    },
    updateItemRequestPending() {
      console.log(this.updateItemRequestPending, 'this update request');
      this.$emit('onUpdateItemRequestPending', this.updateItemRequestPending);
    },
    actionColors(value) {
      this.setWeightColors(this.defaultWeightsSlider, value);
      this.setWeightColors(this.weightsSlider, value);
      this.setWeightColors(this.weights, value);
    },
    tempDiapason: {
      handler() {
        this.changeRangeTable(this.activeParticipant, false);
      },
      deep: true,
      immediate: true
    },
    tempParts: {
      handler() {
        // this.changeRangeTable(this.activeParticipant, false);
        this.watchForUpdates === true
          ? (this.updateAvailable = true)
          : (this.updateAvailable = false);
      },
      deep: true
    },
    activeParticipant(value) {
      if (!this.item) return;
      if (this.item.type === ITEM_TYPE_GROUP) return;
      console.log('reset here,', value);
      this.changeRangeTable(value, true);
      console.log(Object.keys(this.rangeHands).length, 'THIS RANFE HANDS');
      //сохранение рук при переключении табов(устарело)
      // if (Object.keys(this.rangeHands).length) {
      //   this.tempDiapason[oldValue].hands = this.rangeHands;
      // }

      //Раньше тут выбирался активный игрок, теперь это переехало в defaultRangeEditor

      // let activePartsToPlayObject = {}; //Объявляем объект для передачи наверх
      // activePartsToPlayObject[this.item.id] = value;
      // console.log(
      //   activePartsToPlayObject,
      //   'Range Editor Mixin: activaPartsToPlayObject:',
      //   activePartsToPlayObject
      // );

      // this.$emit('setActiveParticipantsToPlay', activePartsToPlayObject);

      // this.tempDiapason[value] = this.rangeHands;

      // this.rangeHands = deepClone(this.tempDiapason[value].hands);

      // let weightsSlider = deepClone(this.defaultWeightsSlider);

      // let additionalProperties = this.item.diapason.additionalProperties;

      // if (
      //   rangeHasCheck(
      //     additionalProperties.type,
      //     additionalProperties.hp,
      //     additionalProperties.n
      //   )
      // ) {
      //   weightsSlider.check.isAvailable = true;
      //   weightsSlider.call.isAvailable = false;
      //   weightsSlider.fold.isAvailable = false;

      //   this.weights.check.isAvailable = true;
      //   this.weights.call.isAvailable = false;
      //   this.weights.fold.isAvailable = false;

      //   weightsSlider.check.value = 34;
      //   weightsSlider.raise.value = 33;
      //   weightsSlider.allin.value = 33;
      //   weightsSlider.call.value = 0;
      //   weightsSlider.fold.value = 0;
      // } else {
      //   weightsSlider.check.isAvailable = false;
      //   weightsSlider.call.isAvailable = true;
      //   weightsSlider.fold.isAvailable = true;

      //   this.weights.check.isAvailable = false;
      //   this.weights.call.isAvailable = true;
      //   this.weights.fold.isAvailable = true;

      //   weightsSlider.check.value = 0;
      //   weightsSlider.call.value = 25;
      //   weightsSlider.fold.value = 25;
      // }

      // this.weightsSlider = weightsSlider;

      // if (this.$refs.rangeSlider) {
      //   this.$refs.rangeSlider.reset();
      // }
      // this.$refs.weightsList.reset();
    },
    item(value) {
      console.log('RangeEditorMixin: item watcher value', value);
      if (!value) return;
      if (value.type === ITEM_TYPE_GROUP) return;

      // this.activeParticipant = null;
      this.activeParticipant = this.item.diapason.additionalProperties.hp;
      console.log(value.diapason, 'RangeEditorMixin: value.diapason');
      // this.tempDiapason = deepClone(value.diapason.participants);

      //   this.$set(
      //     this.parts,
      //     key,
      //     _.cloneDeep(_.omit(this.dataItem.participants[key], ['hands']))
      //   );
      // }
      console.log(value, 'value item watche');
      this.tempDiapason = {};
      for (let key in value.diapason.participants) {
        this.$set(
          this.tempDiapason,
          key,
          _.cloneDeep(_.pick(value.diapason.participants[key], ['hands']))
        );

        // this.tempDiapason[key] = _.cloneDeep(
        //   _.pick(value.diapason.participants[key], ['hands'])
        // );
      }

      console.log(
        this.tempDiapason,
        this.activeParticipant,
        'TEMP DIAPASON FROM ITEM WATCHER'
      );

      //TODO:DOWN HERE
      console.log('RABOTAEM x2', value);
      // this.rangeHands = deepClone(value.diapason.hands);

      this.rangeHands = deepClone(
        this.tempDiapason[this.activeParticipant]?.hands
      );
      console.log(
        this.rangeHands,
        this.tempDiapason,
        'item watcher RangeEditorMixin'
      );
      let weightsSlider = deepClone(this.defaultWeightsSlider);

      let additionalProperties = value.diapason.additionalProperties;

      this.changeRangeTable(this.activeParticipant);

      if (
        rangeHasCheck(
          additionalProperties.type,
          additionalProperties.hp,
          additionalProperties.n
        )
      ) {
        weightsSlider.check.isAvailable = true;
        weightsSlider.call.isAvailable = false;
        weightsSlider.fold.isAvailable = false;

        this.weights.check.isAvailable = true;
        this.weights.call.isAvailable = false;
        this.weights.fold.isAvailable = false;

        weightsSlider.check.value = 34;
        weightsSlider.raise.value = 33;
        weightsSlider.allin.value = 33;
        weightsSlider.call.value = 0;
        weightsSlider.fold.value = 0;
      } else {
        weightsSlider.check.isAvailable = false;
        weightsSlider.call.isAvailable = true;
        weightsSlider.fold.isAvailable = true;

        this.weights.check.isAvailable = false;
        this.weights.call.isAvailable = true;
        this.weights.fold.isAvailable = true;

        weightsSlider.check.value = 0;
        weightsSlider.call.value = 25;
        weightsSlider.fold.value = 25;
      }

      this.weightsSlider = weightsSlider;

      if (this.$refs.rangeSlider) {
        this.$refs.rangeSlider.reset();
      }

      if (this.$refs.weightsList) {
        this.$refs.weightsList.reset();
      }
      if (this.$refs.rangeTableEditor) {
        this.$refs.rangeTableEditor.updateTable();
      }

    }
  }
};
